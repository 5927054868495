<template>
    <video-list></video-list>
</template>
<script>

//const videoList = () => import('../components/VideoList.vue')
const videoList = require('../components/VideoList.vue').default;

export default {
    components: {videoList},
};
</script>
