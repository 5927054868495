export default {
    attributes: [
        'id',
        'lessonId',
        'comment',
        'user_id',
        'created_at',
    ],

    http: {
        baseRoute: '/lesson/{lessonId}/',
        validation: false,
        actions: {
            // Don't expose the destroy action
            destroy: false,
            fetch: {
                method: 'GET',
                route: 'thread',
            },
            post: {
                validation: false,
                method: 'POST',
                route: 'post',
                data: {
                    only: ['comment'],
                },
            },
            update: false,
        },
        getDataFromResponse(response) {
            return response.data.data;
        }
    },
}
