import auth from '../auth';
export default {
    attributes: [
        'first_name',
        'last_name',
        'email',
        'id',
        'instructor_id',
    ],
    http: {

        baseRoute: process.env.MIX_API_ACCOUNT_BASEURL+'/api201902/instructors/',
        axios: function(config, key, definition, runtimeArgs) {
            config.headers = {};
            config.headers['X-Requested-With'] = 'XMLHttpRequest';
            config.headers['Authorization'] = 'Bearer '+ auth.getJwtToken();
            return config;
        },

        actions: {
            // Don't expose the destroy action
            destroy: false,
            update: false,
            fetch: {
                route: '{id}/students',
            },
            byInstructor: {
                route: '{instructor_id}/students?withAcademy=true',
                apply: false,
                method: 'POST',
                data: {
                    only: ['id'],
                    custom: function(payload, definition) {
                        return "ids[]="+payload.id;
                    },
                },
            },
        },
        getDataFromResponse(response) {
            return response.data.data.attributes;
        }
    },
}
