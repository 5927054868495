<template>

        <div>
            <div class="card">
                <div class="card-body">
                    <p>
                    This lesson has not been started by the student.
                    </p>
                    <p>
                    If you need to help the student by starting the lesson for them, you can do so.
                    </p>
                    <button
                        type="button"
                        class="btn btn-success"
                        @click="assignLesson()">Start Lesson</button>
                </div>
            </div>
        </div>

</template>
<script>
import { EventBus } from '../../lib/event-bus.js';
import { auth } from '../../lib/auth/index.js';

export default {
    props: ['lesson', 'attachmentList', 'attachmentCount', 'loading', 'lessonid'],
    data: function() {
        return {
            auth: auth,
        };
    },
    methods: {
        assignLesson: function() {
            var self = this;
            var userId = auth.getId();
            this.lesson.assignTo(userId)
            .then(() => {
                self.$emit('lessonStatusChanged');
            })
        },
        lessonStarted: function() {
            return this.lesson.status != 'ready';
        },
        imageFailed: function(evt) {
            evt.target.src = '/dist/images/locker_placeholder.png';
        },
    },
}
</script>
