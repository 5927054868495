import auth from '../auth';
export default {
    attributes: [
        'first_name',
        'last_name',
        'email',
        'id',
        'pic_url',
    ],
    http: {
        baseRoute: process.env.MIX_API_ACCOUNT_BASEURL+'/api201902/account/',
        axios: function(config, key, definition, runtimeArgs) {
            config.headers = {};
            config.headers['X-Requested-With'] = 'XMLHttpRequest';
            config.headers['Authorization'] = 'Bearer '+ auth.getJwtToken();
            return config;
        },

        actions: {
        },
        getDataFromResponse(response) {
            return response.data.data.attributes;
        }
    },
}
