<template>

              <li class="nav-item dropdown nav__notifications"  :class="{'show': show}"  @click="showIt"  v-click-outside="close">
                <a class="nav-link" id="dropdown__alerticon" href="#" aria-haspopup="true" :aria-expanded="show">
                  <i class="material-icons">notifications</i>
                  <span v-show="unseenCount > 0" class="notification">{{ncount}}</span>
                  <p class="d-lg-none d-md-block">
                    Notifications
                  </p>
                </a>
                <div class="dropdown-menu dropdown-menu-right" @click.stop="stopToggle" :class="{'show': show}" aria-labelledby="navbarDropdownMenuLink">
                    <a v-show="this.notificationList.length < 1" class="dropdown-item" href="#">no notifications</a>
                    <div v-for="n in this.notificationList" class="notification-item ripple" :class="{'notification-item__unread': (n.read_at == null)}">
                        <a :href="'/notifications/'+n.id+'/read/?redir='+n.data.url"><strong>{{n.data.s}}</strong> {{n.data.v}} {{n.data.o}}</a>
                        <span class="notification-meta text-muted">
                            {{n.created_at | humanDateDiff}}
                        </span>
                    </div>
                </div>
              </li>

</template>
<script>
var transientHandler;
export default {
    data: function() {
        return {
            'show': false,
            'unseenCount': 0,
            'notificationList': [],
        }
    },

    directives: {
        'click-outside': {
            bind: function(el,binding, vnode) {
                transientHandler = event =>{ binding.value.call(vnode.context, event); };

                document.body.addEventListener('click', transientHandler)
            },
            unbind: function(el) {
                document.body.removeEventListener('click', transientHandler)
            },
        },
    },
    computed: {
        ncount: function() {
            return this.unseenCount > 9 ? '9+': this.unseenCount;
        }
    },
    mounted: function() {
        this.getData();
    },

    methods: {
        getData: function() {
            axios.get('/notifications/')
            .bind(this)
                .then((response) => {
                    this.unseenCount = response.data.meta.unseenCount;
                    this.notificationList = response.data.data;
                    // only continue polling if not error
                    window.setTimeout(this.getData.bind(this), 60000);
                },
                (response) => {
                    this.unseenCount = 0;
                }
            );
        },
        close: function(event) {
            this.show = false;
        },
        stopToggle: function(event) {
        },
        showIt: function(event) {
            event.stopPropagation();
            this.show = !this.show;
            if (!this.show)
                return;

            window.setTimeout(this.seenNotifications, 4000);
        },
        seenNotifications: function() {
            if (!this.show)
                return;
            axios.post('/notifications/seen')
            .bind(this)
                .then((response) => {
                    this.unseenCount = 0;
                },
                (response) => {
                }
            );
        },
    }
}
</script>
