export default {
    attributes: [
        'academyCode',
        'student_accept_mode',
        'auto_create_lessons',
        'enable_store',
        'stripe_acct',
        'profile_slug',
    ],

    http: {
        baseRoute: '/academy/',
        actions: {
            // Don't expose the destroy action
            destroy: false,
            fetch: {
                route: '{academyCode}/market',
            },
            update: {
                method: 'PATCH',
                route: '{academyCode}/market',
            },
            profileUpdate: {
                method: 'PATCH',
                route: '{academyCode}/profile',
                apply: true,
                data: {
                    only: ['profile_slug'],
                }
            },
        },
        getDataFromResponse(response) {
            return response.data.data;
        }
    },
}
