<template>
    <form>
        <div class="row" v-if="loading">
            <div class="col-12">
                <div class="card">
                    <div class="overlay-spinner" v-show="loading"><scale-loader :loading="loading"></scale-loader></div>
                    <div class="card-body" style="padding:0.55rem;">
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <product-card
                    v-bind:product-list="productListForAcademy(productList)"
                    :title="'Academy-Wide Products'"
                    key="academy"
                    v-on:addProduct="addProduct"
                    v-on:connectToStripe="connectToStripe"
                    v-on:editProduct="editProduct"
                />
            </div>
        </div>

 
        <div class="row">
            <div class="col-12" v-for="inst in instructorList" :key="inst.id">
                <product-card
                    v-bind:product-list="productListForInstructor(productList, inst)"
                    :title="inst.first_name + ' ' +  inst.last_name"
                    :inst="inst"
                    :profile-pic="inst.profile_pic"
                    v-on:addProduct="addProduct"
                    v-on:connectToStripe="connectToStripe"
                    v-on:editProduct="editProduct"
                />
            </div>
        </div>
    </form>
</template>

<script>
const VueModel = require('vue-model').default;
const Product = require('../lib/models/Product.js').default;
const ProductCard = require('../components/AcademyProducts/ProductCard.vue').default;
VueModel.register('product', Product);
const ScaleLoader = require('vue-spinner/src/ScaleLoader.vue').default
//import PictureInput from 'vue-picture-input';
import VueModelErrors from '../lib/VueModelErrors.js'
import MarkdownEditor from '../components/markdown-editor'

Vue.directive('priceOnlyFilter', {
  bind(el) {
    el.addEventListener('keyup', () => {
      let regex = /[^0-9\.]/
      el.value = el.value.replace(regex, '')
    })
  }
})

export default {
    props: [],
    mixins: [VueModelErrors],
    components:{
        'scale-loader': ScaleLoader,
        'product-card': ProductCard,
        'markdown-editor': MarkdownEditor,
    },
    data: function() {
        return {
            baseUrl: process.env.MIX_APP_URL+'/',
            linkCopied: false,
            logoChanged: false,
            bannerChanged: false,
            logoRemoved: false,
            bannerRemoved: false,
            instructorList: [],
            productList: [],
            loading:true,
            showEditDialog:false,
        };
    },
    mounted: function() {
        var self = this;
        self.instructorList = [];
        self.productList = [];
        var academyCode = this.$root.auth.getAcademyCode();
        this.loadProductList().then(() => {

            var instructorLoader = this.$model('instructor', {academyCode: academyCode});
            return instructorLoader.http.index()
            .then(function(response) {
                response.data.data.forEach((item, index) => {
                    console.log(item);
                    self.instructorList.push( self.$model('instructor',
                        Object.assign({id:item.id}, item.attributes) ) );
                    self.loading = false;
                });
            });

        }).then(() => {
            this.setHtml();
        });
    },
    models: {
        listeners: {
            'product.update.error': 'vueModelApiError',
            'product.create.error': 'vueModelApiError',
        },
    },
    methods: {
        filterPrice(event, prod) {
            var value = event.target.value;
            var regex = /[^0-9\.]/gi;
            value = value.replace(regex, '');
            console.log(value);
            this.$set(prod, 'price', value);
            //prod.price = value;
            return false;
        },
        setHtml: function() {
            var self = this;
            this.productList.forEach( prod => {
                if (self.$refs['mde-'+prod.prod_code]) {
                    self.$refs['mde-'+prod.prod_code][0].setHtml(prod.desc);
                }
            });
        },
        productListForInstructor: function(productList, instructor) {
            return productList.filter( (item) => {
                return item.instructor_id === instructor.id;
            });
        },
        productListForAcademy: function(productList) {
            return productList.filter( (item) => {
                return item.instructor_id == null;
            });
        },
        copyLink(str) {
            const el = document.createElement('textarea');
            el.value = str;
            el.setAttribute('readonly', '');
            el.style.position = 'absolute';
            el.style.left = '-9999px';
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            this.linkCopied = true;
            var self = this;
            window.setTimeout(() => {
                self.linkCopied = false;
            }, 1700);
        },
        checkAndSave: function() {
            this.marketSettings.profile_slug = this.marketSettings.profile_slug.trim().replace(/[\s]+/g, '-');
            this.$emit('marketSettingsUpdated');
            if (this.bannerChanged) {
                this.$emit('bannerChanged', this.$refs.bannerInput.file);
            }
            if (this.logoChanged) {
                this.$emit('logoChanged', this.$refs.logoInput.file);
            }
        },
        onRemove (kind) {
            if (kind == 'banner') {
                this.bannerChanged = false;
                this.bannerRemoved = true;
            } else {
                this.logoChanged = false;
                this.logoRemoved = true;
            }
        },
        onChange (kind) {
            if (kind == 'banner') {
                this.bannerChanged = true;
                this.bannerRemoved = false;
            } else {
                this.logoChanged = true;
                this.logoRemoved = false;
            }
        },
        loadProductList: function() {
            const self = this;
            return axios.get('/products/?ac=' + this.$root.auth.getAcademyCode())
                .then( function(response) {
                    const pList = [];

                    response.data.data.forEach( prod => {
                        pList.push(self.$model('product', {
                            display_name: prod.display_name,
                            price: prod.amount,
                            desc: prod.desc,
                            academy_code: prod.academy_code,
                            instructor_id: prod.instructor_id,
                            recurring: prod.recurring,
                            credits: prod.credits,
                            prod_code: prod.prod_code,
                            icon_url: prod.icon_url,
                            stripe_price_id: prod.stripe_price_id
                       }));
                    });
                    self.productList = pList;
                    self.notLoaded = false;
                })
                .error (function (err) {
                    self.$swal({
                        text: "Cannot load products.", 
                        type:'error',
                    });
                    console.error(err);
                });
        },
        hasNoProducts: function(inst) {
            return this.productListForInstructor(this.productList, inst).length < 1;
        },
        defaultDescription: function() {
            return `* Review of up to 2 video captures of your swing.  Down-the-line and face-on analysis of swing using V1's analyzer tool.
 * Voice-over analysis.
 * Chat communication with teaching professional.
 * Identification of your most significant swing faults
 * Corrective exercises and drills specific to your development
 * Use the V1 Golf App to capture your swing and store it in the cloud`;
        },
        addProduct: function(prod, dialog) {
            const newProd = this.$model('product', {
               display_name: prod.display_name,
               price: prod.price,
               desc: prod.desc, 
               academyCode: prod.academy_code,
               instructor_id: prod.inst_id,
               recurring: prod.recurring,
               credits: prod.credits,
               prod_code: null,
            });
            newProd.http.create()
                .then(() => this.loadProductList())
                .then(() => dialog.closeDialog())
                .catch((error) => {
                    console.log(error);
                    dialog.enableSubmit();
                });
        },
        editProduct: function(prod, dialog) {
            const index = this.productList.findIndex(product => product.prod_code === prod.prod_code);
            const originProd = { ...this.productList[index] };
            Object.assign(this.productList[index], prod);
            this.productList[index].http.update()
                .then(() => this.loadProductList())
                .then(() => dialog.closeDialog())
                .catch((error) => {
                    console.log(error);
                    Object.assign(this.productList[index], originProd);
                    dialog.enableSubmit();
                });
        },
        removeProduct: function(prod, idx) {
            var self = this;
            if (prod.prod_code) {
                prod.http.destroy().
                then(()=> {
                    this.productList.splice(idx, 1);
                });
            } else {
                this.productList.splice(idx, 1);
            }
        },
        connectToStripe: function(prod) {
            prod.http.connectToStripe()
                .then(() => this.loadProductList());
        }
    },
}
</script>
<style>
.flex-items-grow > * {
    flex-grow:1;
}
</style>
