export default {
    attributes: [
        'id',
        'lesson_id',
        'instructor_id',
        'account_id',
        'thumb_url',
        'video_url',
        'source_video_url',
        'source_video_id',
        'date_uploaded',
    ],

    http: {
        axios: function(config, key, definition, runtimeArgs) {
            config.headers = {};
            config.headers['X-Requested-With'] = 'XMLHttpRequest';
            return config;
        },
        baseRoute: '/videolessons/',
        actions: {
            // Don't expose the destroy action
            destroy: false,
            fetch: {
                route: '{id}',
            },
            update: {
                route: '{id}',
            },
            store: {
                route: '/lesson/{lesson_id}/analyses',
                method: 'POST',
                apply: true,
            },

        },
        getDataFromResponse(response) {
            return response.data.data;
        }
    },

    methods: {
    },
}
