import auth from '../auth';
export default {
    attributes: [
        'id',
        'prod_code',
        'academy_code',
        'instructor_id',
        'display_name',
        'credits',
        'price',
        'desc',
        'recurring',
        'icon_url',
        'stripe_price_id',
    ],
	methods : {
        getUuid() {
            return this.prod_code;
        },
        getImage() {
            return this.icon_url || "/dist/images/products/lesson-product-2.png";
        },
	},
    http: {
        axios: function(config, key, definition, runtimeArgs) {
            config.headers = {Authorization: 'Bearer '+ auth.getJwtToken()};
            return config;
        },
        actions: {
            // Don't expose the destroy action
            destroy: {
                method: 'DELETE',
                route: 'products/{prod_code}',
                apply: false,
            },
            fetch: {
                route: process.env.MIX_API_ACCOUNT_BASEURL+'/api201902/instructors/{id}',
            },
            index: {
                route: process.env.MIX_API_ACCOUNT_BASEURL+'/api201902/academies/{academyCode}/instructors/?fields[instructor]=bio,philo,accolades',
            },
            update: {
                method: 'POST',
                route: 'products/{prod_code}',
                apply: false,
            },
            connectToStripe: {
                method: 'POST',
                route: 'products/{prod_code}?connect-to-stripe=1',
                apply: false,
            },
            create: {
                method: 'POST',
                route: 'products/',
                apply: true,
            }
        },
        getDataFromResponse(response) {
            return response.data.data;
        },
    },
}
