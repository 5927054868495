<template>
    <div :id="id" >
        <editor
            ref="tuieditor"
            :options="editorOptions"
            initialEditType="wysiwyg"
            @change="onEditorChange"
            @blur="onEditorBlur"
        ></editor>
        </div>
</template>

<script>
// deps for editor
import 'codemirror/lib/codemirror.css' // codemirror
import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/vue-editor'
//import defaultOptions from './default-options'


const defaultOptions = {
    minHeight: '200px',
    previewStyle: 'tab',
    useCommandShortcut: true,
    useDefaultHTMLSanitizer: true,
    usageStatistics: false,
    hideModeSwitch: false,
    toolbarItems: [
        ['heading', 'bold', 'italic', 'strike'],
        ['hr', 'quote'],
        ['ul', 'ol', 'task', 'indent', 'outdent'],
        ['table', 'image', 'link'],
        ['code', 'codeblock'],
    ]
}

export default {
    name: 'MarkdownEditor',
    props: {
        value: {
            type: String,
            default: ''
        },
        id: {
            type: String,
            required: false,
            default() {
                return 'markdown-editor-' + +new Date() + ((Math.random() * 1000).toFixed(0) + '')
            }
        },
        options: {
            type: Object,
            default() {
                return defaultOptions
            }
        },
        mode: {
            type: String,
            default: 'wysiwyg'
        },
        width: {
            type: String,
            required: false,
            default: '600px'
        },
        height: {
            type: String,
            required: false,
            default: '300px'
        },
        language: {
            type: String,
            required: false,
            default: 'en_US' // https://github.com/nhnent/tui.editor/tree/master/src/js/langs
        }
    },
    // data() {
    //     return {
    //         editor: null
    //     }
    // },
    components: {
        editor: Editor,
    },
    computed: {
        editorOptions() {
            const options = Object.assign({}, defaultOptions, this.options)
            options.initialEditType = this.mode
            options.height = this.height
            options.width =  this.width
            options.language = this.language
            options.previewStyle = 'tab';
            return options
        }
    },
    watch: {
        value(newValue, preValue) {
            if (newValue !== preValue && newValue !== this.$refs.tuieditor.invoke('getMarkdown')) {
                this.$refs.tuieditor.invoke('setMarkdown', newValue)
            }
        },
        language(val) {
            this.destroyEditor()
            this.initEditor()
        },
        height(newValue) {
            this.$refs.tuieditor.height(newValue)
        },
        mode(newValue) {
            this.$refs.tuieditor.changeMode(newValue, true)
        }
    },
    mounted() {
        this.initEditor()
    },
    destroyed() {
        this.destroyEditor()
    },
    methods: {
        onEditorChange() {
            this.$emit('input', this.$refs.tuieditor.invoke('getMarkdown'))
        },
        onEditorBlur() {
            this.$emit('blur')
        },
        initEditor() {
            // this.$refs.tuieditor = new Editor({
            //     el: document.getElementById(this.id),
            //     ...this.editorOptions
            // })
            if (this.value) {
                this.$refs.tuieditor.invoke('setMarkdown', this.value)
            }

            // this.$refs.tuieditor.on('change', () => {
            //     this.$emit('input', this.$refs.tuieditor.getValue())
            // })
            // this.$refs.tuieditor.on('blur', () => {
            //     this.$emit('blur')
            // })
        },
        destroyEditor() {
            if (!this.$refs.tuieditor) return
            this.$refs.tuieditor.off('change')
            this.$refs.tuieditor.remove()
        },
        setValue(value) {
            this.$refs.tuieditor.invoke('setMarkdown', value)
        },
        getValue() {
            return this.$refs.tuieditor.invoke('getMarkdown')
        },
        setHtml(value) {
            this.$refs.tuieditor.invoke('setHTML', value)
        },
        getHtml() {
            return this.$refs.tuieditor.invoke('getHTML')
        }
    }
}
</script>
