<template>
<div>
  <transition name="slide-up2" mode="out-in">
      <div v-if="showAttachment">
          <locker-attachment
              :video-locker-list="videoLockerList"
              :items-per-page="8"
              v-on:attachmentsSelected="addAttachments()"
              v-on:cancel="cancelAttachments()"
          ></locker-attachment>
      </div>
      <div v-if="showAnalysisAttachment">
          <locker-attachment
              :video-locker-list="videoLockerList"
              :items-per-page="8"
              v-on:attachmentsSelected="addAnalyses()"
              v-on:cancel="cancelAttachments()"
          ></locker-attachment>
      </div>
  </transition>



  <transition name="slide-up2" mode="out-in">
      <div class="row" v-show="!showAttachment && !showAnalysisAttachment">
      <div class="col-12">

          <lesson-ready
              v-show="!lessonStarted(lesson) && auth.isNotInstructor()"
              v-on:openAttachments="showLocker"
              :lesson="lesson"
              :attachmentList="attachmentList"
              :attachmentCount="attachmentCount"
              :loading="loading"
              :lessonid="id"
          ></lesson-ready>

          <lesson-ready-instructor
              v-show="!lessonStarted(lesson) && auth.isInstructor()"
              v-on:lessonStatusChanged="getLesson()"
              :lesson="lesson"
              :attachmentList="attachmentList"
              :attachmentCount="attachmentCount"
              :loading="loading"
              :lessonid="id"
          ></lesson-ready-instructor>

          <div class="mx-3 p-1 col-12" v-if="!loading && lessonStarted(lesson)">
              <h5>Your Lesson <span class="text-muted" style="font-size:60%">{{id}}</span></h5>

              <span class="badge" :class="lesson.getBadgeColor()">{{lesson.status | capitalize }}</span>
              <span  v-if="lesson.status == 'assigned' || lesson.status == 'completed'">
              Your lesson assigned to: <img height="24" :src="instructorPic(lesson)"> <span>{{instructorName(lesson)}}</span>
              </span>
              <span  v-if="lesson.status == 'waiting'">
                    Your lesson is waiting for an instructor...
              </span>

          </div>

            <div class="card" v-show="loading">
                <div class="card-body">
                    <scale-loader :loading="loading" color="green"></scale-loader>
                </div>
            </div>

            <div class="card" v-show="!loading && lessonStarted(lesson) && lessonAttachments.length > 0">
                <div class="card-body">

                    <div v-show="!loading && lessonStarted(lesson) && lessonAttachments.length > 0">

                            <div class="comment-meta">
                                <img class="img" :src="avatarUrl(lesson.user_id)" height="32">
                                added swing videos
                            </div>


                        <div class="row">
                        <div style="max-width:180px;" class="col-sm-3 col-6" v-for="attach in lessonAttachments">
                            <player-wrapper :video="attach" v-on:playVideo="playVideo"></player-wrapper>

                        </div>
                        </div>
                        <div class="comment-meta-text pt-2 text-muted">
                        </div>
                    </div>

                </div>
            </div>

            <div class="card"  v-show="!loading && lessonStarted(lesson) && hasAnalysis(lesson)">
                <div class="card-body">

                    <div>
                            <div class="comment-meta">
                                <img class="img" :src="avatarUrl(lesson.instructor_id)" height="32">
                                added analysis videos
                            </div>


                        <div class="row">
                        <div style="max-width:180px;" class="col-sm-3 col-6" v-for="attach in lessonAnalyses">
                            <player-wrapper :video="attach" v-on:playVideo="playVideo"></player-wrapper>
                        </div>
                        </div>
                        <div class="comment-meta-text pt-2 text-muted">
                        </div>
                    </div>

                </div>
            </div>

            <div class="card"  v-show="!loading && lessonStarted(lesson) && !hasAnalysis(lesson) && $root.auth.user.isInstructor()">
                <div class="card-body bg-secondary">

                    <div>
                            <div class="comment-meta text-white">
                                <p>
                                Looking to sync your analysis videos?
                                </p>

                                <p>
                                If you have sent response videos from Pro already, click the button
                                to import them here.
                                </p>
                            </div>

                            <button class="btn btn-primary btn__analysis" @click="showAnalysisLocker" :disabled="busyImporting" :class="{disabled: busyImporting}"><i v-if='busyImporting' class='fa fa-spinner fa-spin mr-2'></i>Attach analysis videos</button>
                    </div>

                </div>
            </div>


            <div class="comment-thread">
                <div class="row" v-for="thread in lessonthreadList" v-if="canComment(lesson) && !loading">

                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">

                            <div class="comment-meta">
                                <img class="img" :src="avatarUrl(thread.user_id)" height="32">
                            </div>
                            <markdown-viewer
                              v-if="thread.comment"
                              :id="'mdv-'+thread.id"
                              :initial-value="thread.comment.comment || 'error'"
                            />
                            <div
                                v-if="!thread.comment"
                            >
                            Error reading comment
                            </div>
                            <!--
                            <div v-html="thread.comment.comment"></div>
                            -->

                            <div class="comment-meta-text pt-2 text-muted">
                            {{thread.created_at| humanDateDiff}} on: {{thread.created_at| dateFormat}}
                            </div>

                            </div>
                        </div>
                    </div>
                </div>



                <div class="row"  v-if="canComment(lesson) && !loading && newThread">

                    <div class="col-12">
                        <div class="card">

                            <div class="card-header d-flex justify-content-start">

                            <div class="comment-meta">
                                <img class="img" :src="avatarUrl(this.$root.auth.getId())" height="32">
                            </div>
                            </div>
                            <div class="card-body">
                                <!--
                                <textarea v-model="commentText" style="width:100%"></textarea>
                                -->
                                <markdown-editor
                                    :ref="'mde-thread-comment'"
                                    :mode="'wysiwyg'"
                                    :height="'240px'"
                                    v-model="commentText"
                                />

                            </div>
                            <div class="card-footer text-right justify-content-end">
                                <span
                                    v-if="auth.isInstructor()"
                                >
                                    <button
                                        class="btn btn-outline-success pull-right btn__comment__post mr-4"
                                        @click="completeLesson(lesson)"
                                        :disabled="newThread.http.inProgress"
                                    >Complete</button>
                                </span>

                                <button
                                    class="btn btn-success pull-right btn__comment__post"
                                    @click="postComment"
                                    :disabled="newThread.http.inProgress"
                                >
                                    <i
                                        v-if='newThread.http.inProgress'
                                        class='fa fa-spinner fa-spin'
                                    ></i>
                                    Post Comment</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

    <locker-help-card
        v-show="this.lesson.status == 'ready' && auth.isNotInstructor()"
        ></locker-help-card>

      </div>
      </div>
  </transition>


  <transition name="modal-drop-in" mode="out-in">
<div class="modal fade" tabindex="-1" role="dialog" id="player-modal" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body" style="">
            <d-player  class="video-player-box"
                ref="videoPlayer"
                :options="playerOptions"
                :playsinline="false"
                v-escape-key="hidePlayer"
                @click.stop="emit('noop')"
            >
            </d-player>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

  </transition>


</div>

</template>
<script>
var ScaleLoader = require('vue-spinner/src/ScaleLoader.vue').default;
var LockerHelpCard = require('../components/lesson/LockerHelpCard.vue').default;
var LockerAttachment = require('../components/lesson/LockerAttachment.vue').default;
var LessonReady = require('../components/lesson/LessonReady.vue').default;
var LessonReadyInstructor = require('../components/lesson/LessonReadyInstructor.vue').default;
var PlayerWrapper = require('../components/video/PlayerWrapper.vue').default;
import MarkdownEditor from '../components/markdown-editor'
import MarkdownViewer from '../components/markdown-editor/viewer'

import VueDPlayer from 'vue-dplayer'
import 'vue-dplayer/dist/vue-dplayer.css'

var VueModel = require('vue-model').default;
const LessonThreadModel = require('../lib/models/LessonThread.js').default;
VueModel.register('lessonthread', LessonThreadModel);
VueModel.register('videoanalysis', require('../lib/models/VideoAnalysis.js').default);

import { EventBus } from '../lib/event-bus.js';
import { auth } from  '../lib/auth/index.js';

export default {
    props: ['id'],
    components: {
        'scale-loader': ScaleLoader,
        'locker-help-card': LockerHelpCard,
        'locker-attachment': LockerAttachment,
        'lesson-ready': LessonReady,
        'lesson-ready-instructor': LessonReadyInstructor,
        'player-wrapper': PlayerWrapper,
        'markdown-editor': MarkdownEditor,
        'markdown-viewer': MarkdownViewer,
        'd-player': VueDPlayer,
    },
    data: function() {
        return  {
            auth: auth,
            lesson: {},
            lessonthreadList: [],
            loading:true,
            showAttachment: false,
            showAnalysisAttachment: false,
            videoLockerList: [],
            selectedCount: 0,
            attachmentList: [],
            includedVideoList: [],
            instructor: {},
            commentText: "",
            newThread: this.$model('lessonthread', {lessonId: this.$route.params.id}),
            showPlayer: true,
            busyImporting:false,
            playerOptions: {
                // videojs options
                aspectRatio: "16:9",
                muted: true,
                resizeManager:false,
                language: 'en',
                playbackRates: [0.50, 0.75, 1.0, 1.5, 2.0],
                sources: [{
                    src: "https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm"
                }],
                poster: "/static/images/author.jpg",
            },
        }
    },
    watch: {
        '$route': {immediate:true, handler:'getLesson'},
        'showAttachment': 'fetchVideos',
        'showAnalysisAttachment': 'fetchAnalyses',
    },
    beforeDestroy: function() {
        $('#player-modal').off('hide.bs.modal');
        this.$refs.videoPlayer.dp.destroy();
    },
    mounted: function() {
        //reset any dangling modes
        this.showAttachment = false;
        var self = this;

        $('#player-modal').on('hide.bs.modal', function (e) {
            self.$refs.videoPlayer.dp.pause();
        });
    },
    created: function() {
    //    this.getLesson();
    },
    computed: {
        attachmentCount: function() {
            return this.attachmentList.length;
        },
        lessonAttachments: function() {
            if (!this.lesson || !this.lesson.attachments) {
                return [];
            }
            if (this.lesson.attachments.length == 0) {
                return [];
            }
            return this.lesson.attachments.map((item) => {
                let found= this.includedVideoList.filter(function(included) {
                    return included.id == item;
                });
                return found[0] || undefined;
            })
            .filter(item=>item);
        },
        lessonAnalyses: function() {
            if (!this.lesson) {
                return [];
            }
            if (this.includedVideoList.length == 0) {
                return [];
            }
            var x = this.includedVideoList.filter(function(included) {
                return included.instructor_id != undefined;
            })
            .filter(item=>item);
            console.log(x);
            return x;
        },
    },
    methods: {
        cancelAttachments: function() {
            this.showAttachment = false;
            this.showAnalysisAttachment = false;
            this.lessonAnalyses = [];
            this.attachmentList = [];

            this.videoLockerList.forEach((item) => {
                if (item.selected) {
                    item.selected = false;
                }
            });
        },
        addAttachments: function() {
            this.selectedCount = 0;

            let thisSelection = []
            this.videoLockerList.forEach((item) => {
                if (item.selected) {
                    thisSelection.push(item);
                }
            });
            this.attachmentList = thisSelection;
            this.lesson.pendingAttachmentList = thisSelection;
            this.showAttachment = false;
        },
        addAnalyses: function() {
            this.selectedCount = 0;

            let thisSelection = []
            this.videoLockerList.forEach((item) => {
                if (item.selected) {
                    thisSelection.push(item);
                }
            });
            var self = this;
            thisSelection.forEach((item) => {
                var analysis;
                analysis = this.$model('videoanalysis', item);
                analysis.lesson_id = this.lesson.id;
                analysis.http.store().then((response) => {
                    self.lessonAnalyses.push(analysis);
                    self.includedVideoList.push(analysis);
                });
            });
            this.showAnalysisAttachment = false;
        },
        fetchAnalyses: function() {
            var self = this;
            if (this.videoLockerList.length > 0) {
                return Promise.resolve();
            }
            return self.$api.get('videolessons/')
            .then( function(response) {
                self.videoLockerList = response.data.data.map(item=>{
                    if (item.type == 'video') {
                        return Object.assign({'id': item.id, 'title': ''}, item.attributes);
                    }
                    return undefined;
                })
                .filter(i=>i);
                self.selectedCount = 0;
            });
        },
        fetchVideos: function() {
            var self = this;
            if (this.videoLockerList.length > 0) {
                return Promise.resolve();
            }
            return self.$api.get('locker/')
            .then( function(response) {
                self.videoLockerList = response.data.data.map(item=>{
                    if (item.type == 'video') {
                        return Object.assign({'id': item.id, 'title': ''}, item.attributes);
                    }
                    return undefined;
                })
                .filter(i=>i);
                self.selectedCount = 0;
            });
        },
        showLocker: function() {
            this.showAttachment = true;
        },
        getThread: function() {
            if (!this.$route.params.id) {
                return;
            }
            this.loading = true;
            this.conversation = this.$model('lessonthread', {lessonId: this.$route.params.id});
            return this.conversation.http.fetch()
            .bind(this)
            .then(function(response) {
                this.lessonthreadList = this.$models('lessonthread', response.data.data);
            },() => {
            })
            .finally(()=>{
                //this.loading = false;
            });
        },
        getLesson: function() {
            if (!this.$route.params.id) {
                return;
            }
            this.loading = true;
            this.lesson = this.$model('lesson', {id: this.$route.params.id});
            var lessonPromise = this.lesson.http.fetch()
            .bind(this)
            .then(function(response) {
                if (response.data.included) {
                    Vue.set(this, 'includedVideoList', response.data.included.map(function(included) {
                        if (included.type == 'video') {
                            return Object.assign({'id': included.id}, included.attributes);
                        } else {
                            return undefined;
                        }
                    })
                    .filter(item=>item));
                    Vue.set(this, 'instructor', response.data.included.reduce(function(acc, included, idx) {
                        if (idx == 1) {
                            acc = {};
                        }
                        if (included.type == 'instructor') {
                            return Object.assign(acc, {'id': included.id}, included.attributes);
                        }
                        return acc;
                    }));
                }
            },() => {
            })
            .finally(()=>{
                //this.loading = false;
            });

            this.lessonthreadList = [];
            var threadPromise = this.getThread();
            var self = this;
            Promise.join(threadPromise, lessonPromise,
            function() {
                self.loading = false;
            });
            //console.log(this.$route.params.id);
        },
        getCookie: function(name) {
            var nameEQ = name + "=";
            var ca = document.cookie.split(';');
            for(var i=0;i < ca.length;i++) {
                var c = ca[i];
                while (c.charAt(0)==' ') c = c.substring(1,c.length);
                if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
            }
            return null;
        },
        avatarUrl: function(user_id) {
            if (this.instructor && user_id == this.instructor.id) {
                if (this.instructor.profile_pic) {
                    return this.instructor.profile_pic;
                } else {
                    return this.$apiBaseUrl + 'avatar/'+user_id+'/defaultimage.png';
                }
            }

            if (this.$root.auth.getId() == user_id) {
                return this.$root.auth.user.profilePic;
            }
            return this.$apiBaseUrl + 'avatar/'+user_id+'/defaultimage.png';
        },
        instructorPic: function(lesson) {
            if (this.instructor.profile_pic) {
                return this.instructor.profile_pic;
            }
        },
        instructorName: function(lesson) {
            if (this.instructor.first_name) {
                return this.instructor.first_name + ' ' + this.instructor.last_name;
            }
        },
        hasAnalysis: function(lesson) {
            return this.lessonAnalyses.length > 0;
        },
        lessonStarted: function(lesson) {
            return lesson.status != 'ready';
        },
        canComment: function(lesson) {
            return lesson.status != 'ready' && lesson.status != 'waiting';
        },
        completeLesson: function(lesson) {
            var self = this;
            lesson.complete()
            .then(() => {
                self.$emit('lessonStatusChanged');
            })
        },
        postComment: function() {
            var self = this;

            this.newThread = this.$model('lessonthread', {lessonId: this.$route.params.id});
            this.newThread.comment = this.commentText;

            this.newThread.http.post()
            .bind(this)
            .then((resp)=>{
                this.getThread().then(() => {
                    self.loading = false;
                });
                console.log(resp);

                this.commentText = '';
                this.newThread = this.$model('lessonthread', {lessonId: this.$route.params.id});
            })
            .catch((payload) => {

                var message = "";
                if (payload.response.data.errors[0].message) {
                    message = payload.response.data.errors[0].message;
                }
                if (payload.response.status == 422) {
                    self.$swal({
                        title: "Validation Error",
                        text: message,
                        type: "warning",
                        showCancelButton: false,
                        confirmButtonText: "OK",
                    });
                }

            });
        },
        hidePlayer: function(evt) {
            this.showPlayer = false;
        },
        playVideo: function(evt) {
            var self = this;
            this.showPlayer = true;
            let vidurl = '';
            if (evt.relatedTarget) {
                vidurl = $(evt.relatedTarget).data('video-url');
            } else {
                vidurl = $(evt.target).data('video-url');
            }
            $('#player-modal').on('hide.bs.modal', function (e) {
                self.$refs.videoPlayer.dp.pause();
            });

            this.$refs.videoPlayer.dp.switchVideo(
            {
                url: vidurl,
            });
            $('#player-modal').modal('show');
        },
        showAnalysisLocker: function(evt) {
            this.showAttachment = false;
            this.showAnalysisAttachment = true;
        },
        /*
        startJob: function(evt) {
            this.busyImporting = true;
            axios.post('/legacy-sync')
            .then(function(xhr) {
                window.location.reload();
            });
        },
        */
    },
};
</script>

<style scoped>

.slide-up2-enter-active {
  transition: all .6s ease;
}
.slide-up2-leave-active {
    transition: opacity .1s;
}
.slide-up2-leave-to {
  opacity: 0;
}

.slide-up2-enter, .slide-up-leave-to {
  transform: translateY(40px);
  opacity: 0;
}
.overlay .playWrapper.selected i,
.overlay .playWrapper.selected {
    opacity: 1;
}

</style>
