// define a mixin object
var VueModelError = {
    methods: {
        vueModelApiError(payload) {
            var self = this;
            var err = payload.error;
            if (err.response) {
                if (err.response.status == 401 || err.response.status == 403) {
                    err.handled = true;
                    self.$swal({
                        title: "Permission Denied",
                        type: "warning",
                        showCancelButton: false,
                        confirmButtonText: "OK",
                    });
                }
                if (err.response.status == 404) {
                    err.handled = true;
                    self.$swal({
                        title: "Not Found",
                        type: "warning",
                        showCancelButton: false,
                        confirmButtonText: "OK",
                    });
                }
                if (err.response.status == 422) {
                    err.handled = true;
                    var txt = ''
                    if (err.response.data) {
                        txt = err.response.data.errors.reduce((carry, item)=> {
                            carry += item.details || '';
                            return carry + "\n";
                        }, '');
                    }
                    self.$swal({
                        title: "Validation Error",
                        type: "warning",
                        text: txt,
                        showCancelButton: false,
                        confirmButtonText: "OK",
                    });
                }
                if (err.response.status == 500) {
                    err.handled = true;
                    self.$swal({
                        title: "Unknown Error",
                        type: "warning",
                        showCancelButton: false,
                        confirmButtonText: "OK",
                    });
                }
                //unhandled server response
                //let .catch() handlers run

            } else {
                //unhandled network error
                throw error;
            }
        }
    }
}

export default VueModelError;
