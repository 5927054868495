<template>
                    <div class="card">
                        <div class="card-body" style="padding:0.55rem;" v-if="instructor">

<div 
    v-if="instructor.profile_pic"
    class="img-raised rounded-circle img-fluid pull-left"
    style="max-width:125px; max-height:125px; width:125px; height:125px;background-size:cover; background-position:center;margin-bottom:20px;"
    :style="'background-image:url('+instructor.profile_pic+'?r='+randomNum+');'" ></div>
  <div style="margin-left:145px;">
       <h3 class="card-title"> {{ instructor.first_name }} {{ instructor.last_name }}</h3>
       <h5 class="" v-if="instructor.title">
       {{instructor.title}}
       </h5>
           <p v-if="instructor.bio">
           {{instructor.bio}}
           </p>
       <div
           class="instructorr-show-more"
           :class="{collapse: !showmore}"
           v-if="instructor.hasMoreBio()"
           :id="'instructor-bio-'+instructor.id"
       >
           <p v-if="instructor.accolades">
           {{instructor.accolades}}
           </p>
           <p v-if="instructor.philo">
           {{instructor.philo}}
           </p>
      </div>
    <a href=""
        v-if="instructor.hasMoreBio()"
        @click.prevent="showmore = !showmore"
        :aria-expanded="textValueAriaExapanded"
        :aria-controls="'inst-bio-'+instructor.id"
        :data-target="'#inst-bio-'+instructor.id"
    > <span class="showmore">Show More</span> <span class="showless">Show Less</span> </a>

  </div>


                    </div>
                </div>
</template>

<script>
export default {
    props: {
        'instructor': {
            type: Object,
        }
    },
    computed: {
        textValueAriaExapanded() {
            return this.showmore ? 'true':'false';
        },
        randomQuery() {
            return "r="+(Math.random() * 123);
        },
    },
    data: function() {
        return {
            'inst': this.instructor,
            'showmore': false,
            'randomNum': 0,
        };
    },
}
</script>
<style scoped>
[aria-expanded="true"] > .showmore { display:none;}
[aria-expanded="false"] > .showless { display:none;}
</style>
