<template>
<div class="row">
    <div class="col-12" v-if="videoLockerList.length == 0">
            <div class="card">
                <div class="overlay-spinner" v-show="loading"><scale-loader
                        :loading="loading"
                        :color="'red'"
                    ></scale-loader></div>

                <div
                    class="card-body"
                >
                    <span
                        v-if="!loading"
                    >
                        <h4>You have no videos in your locker.</h4>
                        <p>Use V1 Golf app to upload videos to the cloud.</p>

                        <i class="fa fa-cloud-upload fa-3x text-muted"
                           style="line-height:10rem;font-size:1000%; opacity:0.5"
                        ></i>
                    </span>
                </div>
            </div>
    </div>
    <div class="col-md-3 col-sm-12" v-for="video in videoLockerList">
        <div class="card">
            <div class="overlay"
                @click.prevent="playVideo"
                :data-video-url="video.video_url">
            <img class="card-img-top img-responsive"
                v-bind:src="video.thumb_url"
                alt="Card image cap"
                @error="imageFailed"
                @click.prevent="playVideo"
                :data-video-url="video.video_url">
            <div class="playWrapper"
                @click.prevent="playVideo"
                :data-video-url="video.video_url">
            <i class="material-icons"
                @click.prevent="playVideo"
                :data-video-url="video.video_url">play_circle_outline</i></div>
            </div>
            <div class="card-body">
            <h4 class="card-title"><span :title="video.title">{{video.title | first20Chars}}</span></h4>
            <h6 class="card-title"><span :title="video.date_uploaded">{{video.date_uploaded | humanDateDiff}}</span></h6>
            </div>
        </div>
    </div>

<div class="modal fade" tabindex="-1" role="dialog" id="player-modal" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
            <d-player  class="video-player-box"
                ref="videoPlayer"
                :options="playerOptions"
                :playsinline="false"
                @click.stop="emit('noop')"
            >
            </d-player>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
</div>
</template>

<script>
import failedThumbnailVue from "../mixins/failedThumbnail.vue";
var ScaleLoader = require('vue-spinner/src/ScaleLoader.vue').default;
import VueDPlayer from 'vue-dplayer'
import 'vue-dplayer/dist/vue-dplayer.css'


Vue.filter("first20Chars", function (value) {
    return value.substr(0, 20) + (value.length > 20 ? ' ...': '');
});

export default {
    mixins: [failedThumbnailVue],
    components: {
        'scale-loader': ScaleLoader,
        'd-player': VueDPlayer
    },
    data: function() {
        return {
            loading: false,
            videoLockerList: [],
			playerOptions: {
			  // videojs options
			  muted: true,
			  language: 'en',
			  playbackRates: [0.50, 0.75, 1.0, 1.5, 2.0],
			  sources: [{
				src: "https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm"
			  }],
			  poster: "/static/images/author.jpg",
			},
        }
    },
    mounted: function() {
        var self = this;
        self.loading = true;
        self.$api.get('locker/')
        .then( function(response) {
            Vue.set(self, 'videoLockerList', response.data.data.map(function(data) {
                if (data.type == 'video') {
                    return Object.assign({'id': data.id}, data.attributes);
                } else {
                    return undefined;
                }
            })
            .filter(item=>item));

            //self.videoLockerList = response.data.data;
        })
        .finally(() => {
            self.loading = false;
        });
		$('#player-modal').on('hide.bs.modal', function (e) {
            try {
                self.$refs.videoPlayer.dp.pause();
            } catch (e) {
                //player.tech is not ready
            }
		});
    },
    methods: {
        playVideo: function(evt) {
            let vidurl = '';
            if (evt.relatedTarget) {
                vidurl = $(evt.relatedTarget).data('video-url');
            } else {
                vidurl = $(evt.target).data('video-url');
            }
            this.$refs.videoPlayer.dp.switchVideo(
            {
                url: vidurl,
            });

            $('#player-modal').modal('show');
        },
        imageFailed: function(evt) {
            if (evt.target.getAttribute('data-failed')) {
                return this.imageFailedFinal (evt);
            }
            evt.target.src = '/dist/images/locker_placeholder.png';
            evt.target.setAttribute('data-failed', 'true');
            var stagger = Math.floor(Math.random() * (1800 - 400 + 1)) + 400;

            //FIXME: don't hardcode in a static ip for thumbnailer
            //stagger load because this is not load balanced
            window.setTimeout(
            () => {
                evt.target.src="https://vthumb.do.v1sports.com/?fmt=jpg&url="+evt.target.getAttribute('data-video-url');
            },stagger);

        },
        getCookie: function(name) {
			var nameEQ = name + "=";
			var ca = document.cookie.split(';');
			for(var i=0;i < ca.length;i++) {
				var c = ca[i];
				while (c.charAt(0)==' ') c = c.substring(1,c.length);
				if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
			}
			return null;
        },
		beforeDestroy: function() {
			this.$refs.videoPlayer.dp.destroy();
			console.log('destroy');
		},
    }
}

</script>
<style> 
.video-player-box>.video-js {
    max-width:100%;
    margin:auto;
}
.overlay {
    position:relative;
}
 .overlay .playWrapper {
    cursor: pointer;
    opacity: 1;
    position: absolute; 
    z-index: 1;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6)/*  url("http://wptf.com/wp-content/uploads/2014/05/play-button.png") no-repeat scroll center center / 50px 50px*/;
}
.overlay:hover .playWrapper i,
.overlay:hover .playWrapper {
    opacity: 1;
}
.overlay .playWrapper i {
    opacity: 0;
    font-size: 48px;
    color:white;
    position:absolute;
}
.video-js .vjs-big-play-button {
    top: 42%;
    left: 42%;
}
</style>
