<template>
<div>

    <div class="row" v-if="!isOwner()">
        <div class="col-12">
            <div class="card">
                <div class="card-header card-header-vos card-header-text">
                    <h4 class="card-text"><i class="material-icons">store_mall_directory</i>Academy Profile</h4>
                </div>

                <div class="card-body">
                    Only the academy owner can change these settings.
                </div>
            </div>
        </div>
    </div>

		<div class="row" v-if="isOwner()">
			<div class="col-12">
				<div class="card">
                    <div class="card-header card-header-vos card-header-text">
                        <h4 class="card-text"><i class="material-icons">store_mall_directory</i>Academy Profile</h4>
                    </div>
 
					<div class="card-body">

					<form>
                    <div class="row">
                      <div class="col-md-5">
                        <div class="form-group bmd-form-group is-filled">
                          <label class="bmd-label-floating">Academy Code (disabled)</label>
                          <input type="text" class="form-control" disabled="" :value="academyCode">
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group bmd-form-group is-filled">
                          <label class="bmd-label-floating">Academy Name</label>
                          <input type="text" class="form-control" v-model="academy.name">
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group bmd-form-group">
                          <label class="bmd-label-floating">Address</label>
                          <input type="text" class="form-control" v-model="academy.address">
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group bmd-form-group">
                          <label class="bmd-label-floating">City</label>
                          <input type="text" class="form-control" v-model="academy.city">
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group bmd-form-group">
                          <label class="bmd-label-floating">State</label>
                          <input type="text" class="form-control" v-model="academy.state">
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group bmd-form-group">
                          <label class="bmd-label-floating">Postal Code</label>
                          <input type="text" class="form-control" v-model="academy.postal">
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group bmd-form-group">
                          <label class="bmd-label-floating">Tag Line</label>
                          <input type="text" class="form-control" v-model="academyBranding.banner_text">
                        </div>
                      </div>
                    </div>
                    <button type="submit" class="btn btn-primary pull-right"  @click.prevent='saveProfile' :disabled="academy.http.updateInProgress"><i class="material-icons" v-show="academy.http.updateInProgress">sync</i> Update Profile</button>
                    <div class="clearfix"></div>
                  </form>

					</div>
				</div>
			</div>
		</div>

    <div class="row" v-if="!isOwner()">
        <div class="col-12">
            <div class="card">
                <div class="card-header card-header-vos card-header-text">
                    <h4 class="card-text"><i class="material-icons">settings_application</i>Public Page</h4>
                </div>

                <div class="card-body">
                    Only the academy owner can change these settings.
                </div>
            </div>
        </div>
    </div>
    <academy-profile-settings
        v-if="isOwner()"
        :marketSettings="this.marketSettings"
        :loading="publicPageFormInProgress"
        :logo="this.academyBranding.logo"
        :bannerGraphic="this.academyBranding.banner_graphic"
        v-on:marketSettingsUpdated="this.marketSettings.http.profileUpdate"
        v-on:bannerChanged="this.academyBrandingUpdateBanner"
        v-on:logoChanged="this.academyBrandingUpdateLogo"
    ></academy-profile-settings>

    <div class="row" v-if="!isOwner()">
        <div class="col-12">
            <div class="card">
                <div class="card-header card-header-vos card-header-text">
                    <h4 class="card-text"><i class="material-icons">attach_money</i>Marketplace Settings</h4>
                </div>

                <div class="card-body">
                    Only the academy owner can change these settings.
                </div>
            </div>
        </div>
    </div>
	<form
        v-if="isOwner()"
        >
		<div class="row">
			<div class="col-12">
				<div class="card">
                    <div class="overlay-spinner" v-show="publicPageFormInProgress"><scale-loader :loading="publicPageFormInProgress"></scale-loader></div>
                    <div class="card-header card-header-vos card-header-text">
                        <h4 class="card-text"><i class="material-icons">attach_money</i>Marketplace Settings</h4>
                    </div>
 
					<div class="card-body">

                        <div class="row">
                      <label class="col-sm-2 col-form-label label-checkbox">Accept new students?</label>
                      <div class="col-sm-10 checkbox-radios">
                        <div class="form-check">
                          <label class="form-check-label">
                            <input class="form-check-input" type="radio" name="exampleRadios" value="auto"  v-model="marketSettings.student_accept_mode" checked=""> Yes, automatically accept new students.
                            <span class="circle">
                              <span class="check"></span>
                            </span>
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input class="form-check-input" type="radio" name="exampleRadios" value="moderate" v-model="marketSettings.student_accept_mode"> Yes, but require my approval.
                            <span class="circle">
                              <span class="check"></span>
                            </span>
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input class="form-check-input" type="radio" name="exampleRadios" value="disabled" v-model="marketSettings.student_accept_mode"> Not accepting new students at this time.
                            <span class="circle">
                              <span class="check"></span>
                            </span>
                          </label>
                        </div>

                      </div>
                    </div>

                    <div class="row">
                      <label class="col-sm-2 col-form-label label-checkbox">Require Payment for Online Lessons?</label>
                      <div class="col-sm-10 checkbox-radios">
                        <div class="form-check">
                          <label class="form-check-label">
                            <input class="form-check-input" type="radio" name="requirePayment" value="no"
                                v-model="marketSettings.auto_create_lessons"
                            > Yes, reject videos sent to me unless a lesson has been purchased
                            <span class="circle">
                              <span class="check"></span>
                            </span>
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input class="form-check-input" type="radio" name="requirePayment" value="yes"
                                v-model="marketSettings.auto_create_lessons"
                                checked=""
                                > No, create lessons from videos students send me, I will manage billing on my own.
                            <span class="circle">
                              <span class="check"></span>
                            </span>
                          </label>
                        </div>

                      </div>
                    </div>



                     <div class="row">
                      <label class="col-sm-2 col-form-label label-checkbox">Online sales</label>
                      <div class="col-sm-10 checkbox-radios">
                        <div class="form-check">
                          <label class="form-check-label">
                            <input class="form-check-input" type="checkbox" value="1" v-model="marketSettings.enable_store"> Enable lesson products
                            <span class="form-check-sign">
                              <span class="check"></span>
                            </span>
                          </label>
                        </div>
                     </div>

                    </div>

                    <div class="row">
                      <label class="col-sm-2 col-form-label">Stripe payment settings</label>
                      <div class="col-sm-10">

                        <div class="form-group bmd-form-group ">
                          <label class="bmd-label-floating">Stripe Merchant Code</label>
                          <input type="text" class="form-control" v-model="marketSettings.stripe_acct">
                        </div>
                     </div>
                    </div>

                    <div class="row">
                      <label class="col-sm-2 col-form-label">Stripe Integration </label>
                      <div class="col-sm-10">

                        <div class="form-group bmd-form-group ">
                          <label class="bmd-label-floating">Stripe Merchant Code</label>

							<a target="_blank" :href="'https://connect.stripe.com/oauth/authorize?response_type=code&client_id='+MIX_STRIPE_CLIENT_ID+'&scope=read_write&redirect_uri='+MIX_STRIPE_REDIR_URI" class="connect-button btn btn-linkedin">
						  <i class="fa fa fa-cc-stripe"></i> <span>Connect with Stripe</span></a>

                        </div>
                     </div>
                    </div>


                    <button type="submit" class="btn btn-primary pull-right"  @click.prevent='marketSettings.http.update();' :disabled="marketSettings.http.updateInProgress"><i class="material-icons" v-show="marketSettings.http.updateInProgress">sync</i> Update Settings</button>
                    <div class="clearfix"></div>

					</div>
				</div>
			</div>
		</div>

	</form>


</div>
</template>

<script>
import AcademyProfileSettings from './academy/ProfileSettings.vue'
import PictureInput from 'vue-picture-input';
import AcademyBranding from '../lib/models/AcademyBranding.js'
import VueModelErrors from '../lib/VueModelErrors.js'
const VueModel = require('vue-model').default;
const ScaleLoader = require('vue-spinner/src/ScaleLoader.vue').default
VueModel.register('academyBranding', AcademyBranding);

export default {
    props: ['academyCode'],
	mixins: [VueModelErrors],
    components : {
        'academy-profile-settings': AcademyProfileSettings,
        'picture-input': PictureInput,
        'scale-loader': ScaleLoader,
    },
    models: {
        listeners: {
            'academyBranding.uploadLogo.error': 'vueModelApiError',
            'academyBranding.uploadBanner.error': 'vueModelApiError',
        },
    },
    computed: {
        publicPageFormInProgress: function() {
            return this.marketSettings.http.inProgress ||
                this.academyBranding.http.inProgress;
        }
    },
    data: function() {
        return {
            academy: this.$model('academy', {academyCode: this.academyCode}), //http.fetch(),
            marketSettings: this.$model('marketSettings', {academyCode: this.academyCode}), //http.fetch(),
            academyBranding: this.$model('academyBranding', {academyCode: this.academyCode}), //http.fetch(),
            productList: [],
            notLoaded: true,
            orderId: null,
            cart: {products:[], handling:0},
            recentlyAdded: false,
            prodAddLoading: false,
            checkout: false,
			MIX_STRIPE_CLIENT_ID: process.env.MIX_STRIPE_CLIENT_ID,
			MIX_STRIPE_REDIR_URI: process.env.MIX_STRIPE_REDIR_URI,
        }
    },
    mounted: function() {
        this.academy.http.fetch().then(() => {
           $('.bmd-form-group').addClass('is-filled');
        }).then(() => {
            this.marketSettings.http.fetch().then(() => {
               $('.bmd-form-group').addClass('is-filled');
            });

            this.academyBranding.http.fetch().then(() => {
            });
        });
    },
    methods: {
        isOwner: function() {
            return this.$root.auth.user.doesOwnAcademy(this.academyCode);
        },
        academyBrandingUpdateBanner: function(payload) {
            this.academyBranding.updateBanner(payload)
            //handled with vueModelApiError listener
            .catch((err) => {});
        },
        academyBrandingUpdateLogo: function(payload) {
            this.academyBranding.updateLogo(payload)
            //handled with vueModelApiError listener
            .catch((err) => {});
        },
        saveProfile() {
            //academy is only saved to new MySQL
            this.academy.http.update();
            console.log(this.academy);
            //branding is pushed to legacy DB, but is not part of the form
            this.academyBranding.name = this.academy.name;
            this.academyBranding.http.update();
            //.catch(err) => {});
        },
    },
}
</script>
