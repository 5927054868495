<template>
    <viewer
        :id="id"
        ref="tuiviewer"
    ></viewer>
</template>

<script>
// deps for editor
import 'codemirror/lib/codemirror.css' // codemirror
// deps for editor
import 'codemirror/lib/codemirror.css' // codemirror
import '@toast-ui/editor/dist/toastui-editor.css';
import '@toast-ui/editor/dist/toastui-editor-viewer.css';
import { Viewer } from '@toast-ui/vue-editor'
//import defaultOptions from './default-options'


function youtubePlugin() {
    Viewer.codeBlockManager.setReplacer('youtube', function(youtubeId) {
        youtubeId = youtubeId.replace(/^\s+|\s+$/g, '');
        // Indentify multiple code blocks
        const wrapperId = `yt${Math.random()
                .toString(36)
                .substr(2, 10)}`;

        var ytid = youtubeId;
        if (youtubeId.substr(0,4) == 'http') {
            ytid = youtubeId.match(/(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/i)[1];
        }

        // Avoid sanitizing iframe tag
        setTimeout(renderYoutube.bind(null, wrapperId, ytid), 800+Math.random(100,700));

        return `<div id="${wrapperId}"></div>`;
    });
}

function renderYoutube(wrapperId, youtubeId) {
    const el = document.querySelector(`#${wrapperId}`);

    el.innerHTML = `<iframe width="640" height="360" src="https://www.youtube.com/embed/${youtubeId}"></iframe>`;
}

const defaultOptions = {
}

export default {
    name: 'MarkdownViewer',
    components: {
        viewer: Viewer,
    },
    props: {
        initialValue: {
            type: String,
            default: ''
        },
        id: {
            type: String,
            required: false,
            default() {
                return 'markdown-viewer-' + +new Date() + ((Math.random() * 1000).toFixed(0) + '')
            }
        },
        width: {
            type: String,
            required: false,
            default: '600px'
        },
        height: {
            type: String,
            required: false,
            default: '300px'
        },
    },
    data() {
        return {
            viewer: null
        }
    },
    computed: {
    },
    watch: {
        value(newValue, preValue) {
            if (newValue !== preValue && newValue !== this.viewer.getValue()) {
                this.$refs.tuiviewer.setValue(newValue)
            }
        },
        language(val) {
            this.destroyEditor()
            this.initEditor()
        },
        height(newValue) {
            this.$refs.tuiviewer.height(newValue)
        },
        mode(newValue) {
            this.$refs.tuiviewer.changeMode(newValue)
        }
    },
    mounted() {
        this.initEditor()
        youtubePlugin();
    },
    destroyed() {
        this.destroyEditor()
    },
    methods: {
        initEditor() {
           if (this.initialValue) {
                this.$refs.tuiviewer.invoke('setMarkdown', this.initialValue)
            }
            /*
            var self = this;
            setTimeout(function() {
                self.$refs.tuiviewer.setValue = self.initialValue;
            }, 1000);
            */
        },
        destroyEditor() {
            if (!this.$refs.tuiviewer) return
            this.$refs.tuiviewer.off('change')
            this.$refs.tuiviewer.remove()
        },
        setValue(value) {
            this.$refs.tuiviewer.setValue(value)
        },
        getValue() {
            return this.$refs.tuiviewer.getValue()
        },
        setHtml(value) {
            this.$refs.tuiviewer.setHtml(value)
        },
        getHtml() {
            return this.$refs.tuiviewer.getHtml()
        },
    }
}
</script>
