<template>
<div>
    <academy-card
        v-for="z in academyList"
        v-bind:key="z.id"
        v-bind:item="z"
        v-on:reloadWithBrand="reloadWithBrand"
        :class="{'selected-academy':z.selected}"
    />
</div>
</template>

 <script>
import AcademyCard from '../components/AcademyCard'
import { EventBus } from '../lib/event-bus.js';
import axios from 'axios';
Vue.filter("wholeNumber", function (value) {
    return Number.parseInt(value);
});
export default {
    components : {
        'academy-card': AcademyCard,
    },
    data: function() {
        return {
            academyList: [],
        }
    },
    mounted: function() {
        var self = this;
        EventBus.$on('selectedAcademy', function(code) {
//            self.$root.$router.push({ path: '/home'});
        });
        this.loadAcademies();
    },
    methods: {
        reloadWithBrand: function(academy) {
            //console.log(academy);
            var acbc = academy.profile_slug || academy.academy_code;
            window.location = '/'+acbc;
        },
        loadAcademies: function() {
            var self = this;
            EventBus.$on('selectedAcademy', function(code) {
                self.loading = false;

                self.academyList.forEach( (item, index) => {
                    if (item.academy_code == code){
                        this.$set(self.academyList[index], 'selected', true);
                    } else {
                        this.$set(self.academyList[index], 'selected', false);
                    }
                });
            });

            axios.get('academy/list/')
                .then( function(response) {
                    var selectedAcademyCode = self.$root.selectedAcademy;
                    self.academyList = [];
                    //console.log(response.data.data);
                    response.data.data.forEach( item => {
                        item.selected = false;
                        if(item.academy_code === selectedAcademyCode) {
                            item.selected = true;
                        }
                        self.academyList.push( item );
                    });

                    self.loading   = false;
                })
                .error (function (err) {
                    self.$swal({
                        text: "Cannot load list of academies :(",
                        type:'error',
                    });
                    console.error(err);
                    self.loading   = false;
                });
        },
    },
    computed: {
	},
}
</script>

