<template>

  <transition name="slide-left">
<div>

    <data-table
    :title="''"
    :headers="headers"
    :notLoaded="!loaded"
    :items="itemList"
    :status="'completed'"
    :page-size="previewLimit"
    :filter-cols="['student']"
    >
    <template v-slot:cardbody>
    </template>

    <template v-slot:item.user_id="{ item }">
    </template>

    <template v-slot:item.student="{ item }">
        <img height="28" width="28" :src="item.pic_url">
        <router-link
            :to="{name:'studentdetail', params: {student:item, account_id: item.id}}">{{getStudentName(item)}}</router-link>

    </template>

    <template v-slot:item.date="{ item }">
          {{item.joined_at | humanDateDiff }}
          <span style="font-size:75%">
              ({{item.joined_at | dateFormat }})
          </span>
    </template>
    </data-table>
</div>
  </transition>
</template>

<script>
import auth from '../../lib/auth';
import DataTable from '../mddatatable/DataTableCard.vue';
import LoadsJsonApiData from '../../mixins/loadsJsonApiData.vue';

Vue.filter("wholeNumber", function (value) {
    return Number.parseInt(value);
});
export default {
    mixins: [LoadsJsonApiData],
    components: {
        DataTable
    },
    data: function() {
        return {
            url: 'instructors/'+auth.getId()+'/students?limit=300&withAcademy=true',
            itemModel: 'student',
            itemList: [],
            itemTotal: 0,
            loaded: false,
            previewLimit: 10,
            selectedStudent: null,
            detail: false,
            'headers': [
                {text: 'Student', value: 'student', derived: "item.first_name + ' ' + item.last_name"},
                {text: 'Email', value: 'email'},
                {text: 'Date', value: 'date'},
            ],
        }
    },
    computed: {
        dataListPreview: function() {
            return this.itemList.slice(0, this.previewLimit);
        },
        lessonListRemainingCount: function() {
            if (this.itemList.length <= this.previewLimit ) {
              return false;
            }
            return this.itemTotal - this.previewLimit;
        },
    },
    methods: {
        studentDetail: function(student) {
            this.selectedStudent = student;
            this.detail = true;
            this.$refs['studentDetail'].getData(student);
        },
        getStudentName: function(student) {
            //return student.first_name;
            return student.last_name + ', ' + student.first_name;
        },
        getBadgeColor: (lesson) => {
            switch (lesson.status) {
                case 'waiting': return 'badge-warning';
                case 'ready': return 'badge-success';
                default: return 'badge-success';
            }
        },
    },
    mounted: function() {
        this.getData();
    },
}
</script>
