import auth from '../auth';
export default {
    attributes: [
        'id',
        'academyCode',
        'first_name',
        'last_name',
        'profile_pic',
        'title',
        'bio',
        'philo',
        'accolades',
    ],
	methods : {
		hasMoreBio: function() {
			return  (( this.accolades && this.accolades != '')
			|| ( this.philo && this.philo != ''));
		},
	},

    http: {
        //baseRoute: process.env.MIX_API_ACCOUNT_BASEURL+'/api201902/academies/{academyCode}/instructors/?fields[instructor]=bio,philo,accolades',
        axios: function(config, key, definition, runtimeArgs) {
            config.headers = {Authorization: 'Bearer '+ auth.getJwtToken()};
            return config;
        },
        actions: {
            // Don't expose the destroy action
            destroy: false,
            fetch: {
                route: process.env.MIX_API_ACCOUNT_BASEURL+'/api201902/instructors/{id}',
            },
            index: {
                route: process.env.MIX_API_ACCOUNT_BASEURL+'/api201902/academies/{academyCode}/instructors/?fields[instructor]=bio,philo,accolades',
            },
            update: {
                method: 'POST',
                route: process.env.MIX_API_ACCOUNT_BASEURL+'/api201902/instructors/{id}',
                //let's not change the academyCode, since it's one to many
                //and doesn't mean anything here
                data: {
                    without: ['academyCode'],
                },
            },
        },
        getDataFromResponse(response) {
            return response.data.data.attributes;
            return Object.assign({id: response.data.data.id}, response.data.data.attributes);

        },
    },
}
