<template>
            <div class="overlay"
                @click.prevent="playVideoProxy"
                :data-video-url="video.video_url">
            <img class="card-img-top img-responsive"
                v-bind:src="video.thumb_url"
                alt="Card image cap"
                @error="thumbnailFailed"
                @click.prevent="playVideoProxy"
                :data-video-url="video.video_url">
            <div class="playWrapper"
                @click.prevent="playVideoProxy"
                :data-video-url="video.video_url">
            <i class="material-icons"
                @click.prevent="playVideoProxy"
                :data-video-url="video.video_url">play_circle_outline</i></div>
            </div>
</template>

<script>
import FailedThumbnail from '../../mixins/failedThumbnail.vue';
export default {
    props: ['video'],
    mixins: [FailedThumbnail],
    methods: {
        playVideoProxy: function(evt) {
            this.$emit('playVideo', evt);
        },
        playVideo: function(evt) {
            let vidurl = '';
            if (evt.relatedTarget) {
                vidurl = $(evt.relatedTarget).data('video-url');
            } else {
                vidurl = $(evt.target).data('video-url');
            }
            this.$refs.videoPlayer.options['sources'] = [{'type': 'video/mp4', 'src': vidurl}];
            this.$refs.videoPlayer.player.src({'type': 'video/mp4', 'src': vidurl});
            $('#player-modal').modal('show');
        },
    },
}
</script>
<style css-scoped>
.video-player-box>.video-js {
    max-width:100%;
    margin:auto;
}
.overlay {
    position:relative;
}
 .overlay .playWrapper {
    cursor: pointer;
    opacity: 1;
    position: absolute; 
    z-index: 10;
    top: 0;
    width: 100%;
    height: 100%;
    background:rgba(0,0,0,0.4);
}
.overlay:hover .playWrapper i,
.overlay:hover .playWrapper {
    opacity: 1;
}
.overlay .playWrapper i {
    text-align:center;
    opacity: 1;
    font-size: 48px;
    color:white;
    left: 0;
    height:50%;
    width:100%;
    position:relative;
    top:50%;
    margin:0 auto;
    margin-top:-24px;
}
</style>
