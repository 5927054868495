<template>
    <form>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="overlay-spinner" v-show="loading"><scale-loader :loading="loading"></scale-loader></div>
                    <div class="card-header card-header-vos card-header-text">
                        <h4 class="card-text"><i class="material-icons">settings_application</i>Public Page</h4>
                    </div>
 
                <div class="card-body">


                    <div class="row">
                        <div class="col">
                            <label for="profile_slug">Public URL</label>
                            <div class="input-group mb-2">
                                <div class="input-group-prepend">
                                    <span
                                        v-html="this.baseUrl"
                                        class="input-group-text"
                                    >/</span>
                                </div>
                                <input type="text" class="form-control" id="profile_slug" v-model="marketSettings.profile_slug">

                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                             <div class="input-group mb-2">
                                 <div class="input-group-prepend">
                                     <span class="input-group-text">Preview:</span>
                                 </div>
                                 <a
                                     class="input-group-text text-primary"
                                     target="_blank"
                                     :href="'/'+marketSettings.profile_slug+'?no-cache'"
                                     v-html="this.baseUrl+marketSettings.profile_slug"
                                 ></a>&nbsp;<i class="material-icons">open_in_new</i>
                             </div>
                             <div class="input-group mb-2">
                                 <div class="input-group-prepend">
                                     <span class="input-group-text">
                                         <a
                                             href="#"
                                             v-on:click.prevent="copyLink(baseUrl+marketSettings.profile_slug)"
                                             v-html="'copy sharable link'"
                                         ></a>
                                         <div
                                             v-if="linkCopied"
                                             style="
                                                 margin-left:1.5em;
                                                 display: inline-block;
                                                 padding: 0 10px;
                                                 height: 1.25em;
                                                 font-size: 1em;
                                                 line-height: 1.25em;
                                                 border-radius: 10px;
                                                 background-color: #eee;"
                                             class="pill">copied</div>
                                     </span>
                                 </div>
                             </div>
                         </div>
                    </div>


                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group bmd-form-group">
                          <label for="academy_logo">Logo</label>
                              <picture-input
                                  id="academy_logo"
                                  ref="logoInput"
                                  @remove="onRemove('logo')"
                                  @change="onChange('logo')"
                                  width="128"
                                  height="128"
                                  margin="16"
                                  accept="image/jpeg,image/png"
                                  size="100"
                                  :zIndex="0"
                                  :prefill="logo"
                                  :removable="false"
                                  :customStrings="{
                                    upload: '<h1>Bummer! No file upload</h1>',
                                    drag: 'Drop Photo or Click'
                                  }">
                            </picture-input>

                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group bmd-form-group">
                          <label for="academy_logo">Background Banner</label>
                              <picture-input
                                  id="academy_banner"
                                  ref="bannerInput"
                                  @remove="onRemove('banner')"
                                  @change="onChange('banner')"
                                  width="640"
                                  height="128"
                                  margin="16"
                                  accept="image/jpeg,image/png"
                                  size="100"
                                  :zIndex="0"
                                  :prefill="bannerGraphic"
                                  :removable="false"
                                  :customStrings="{
                                    upload: '<h1>Bummer! No file upload</h1>',
                                    drag: 'Drop Photo or Click'
                                  }">
                            </picture-input>

                        </div>
                      </div>
                    </div>


                    <button type="submit" class="btn btn-primary pull-right"  @click.prevent='checkAndSave();' :disabled="loading"><i class="material-icons" v-show="loading">sync</i> Update Settings</button>
                    <div class="clearfix"></div>

                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
const VueModel = require('vue-model').default;
const AcademyProfile = require('../../lib/models/AcademyProfile.js').default;
VueModel.register('academyProfile', AcademyProfile);
const ScaleLoader = require('vue-spinner/src/ScaleLoader.vue').default
import PictureInput from 'vue-picture-input';

export default {
    props: ['marketSettings', 'logo', 'bannerGraphic', 'loading'],
    components:{
        'scale-loader': ScaleLoader,
        'picture-input': PictureInput,
    },
    data: function() {
        return {
            baseUrl: process.env.MIX_APP_URL+'/',
            linkCopied: false,
            logoChanged: false,
            bannerChanged: false,
            logoRemoved: false,
            bannerRemoved: false,
        };
    },
    mounted: function() {
    },
    methods: {
        copyLink(str) {
            const el = document.createElement('textarea');
            el.value = str;
            el.setAttribute('readonly', '');
            el.style.position = 'absolute';
            el.style.left = '-9999px';
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            this.linkCopied = true;
            var self = this;
            window.setTimeout(() => {
                self.linkCopied = false;
            }, 1700);
        },
        checkAndSave: function() {
            this.marketSettings.profile_slug = this.marketSettings.profile_slug.trim().replace(/[\s]+/g, '-');
            this.$emit('marketSettingsUpdated');
            if (this.bannerChanged) {
                this.$emit('bannerChanged', this.$refs.bannerInput.file);
            }
            if (this.logoChanged) {
                this.$emit('logoChanged', this.$refs.logoInput.file);
            }
        },
        onRemove (kind) {
            if (kind == 'banner') {
                this.bannerChanged = false;
                this.bannerRemoved = true;
            } else {
                this.logoChanged = false;
                this.logoRemoved = true;
            }
        },
        onChange (kind) {
            if (kind == 'banner') {
                this.bannerChanged = true;
                this.bannerRemoved = false;
            } else {
                this.logoChanged = true;
                this.logoRemoved = false;
            }
        },
    },
}
</script>
