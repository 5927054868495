export default {
    attributes: [
        'id',
        'pendingAttachmentList',
        'attachments',
        'instructor_id',
        'status',
        'waiting_at',
        'assigned_at',
        'completed_at',
    ],

    http: {
        axios: function(config, key, definition, runtimeArgs) {
            config.headers = {};
            config.headers['X-Requested-With'] = 'XMLHttpRequest';
            return config;
        },
        baseRoute: '/lesson/',
        actions: {
            // Don't expose the destroy action
            destroy: false,
            fetch: {
                route: '{id}',
            },
            update: {
                route: '{id}',
            },
            assign: {
                route: '{id}/assign/{instructor_id}',
                apply: true,
                method: 'POST',
            },
            index: {
                route: '/lessons',
            },
            studentHistory: {
                route: '/lessons/student/{student_id}',
                apply: false,
                method: 'GET',
            },
            complete: {
                route: '{id}/complete/',
                apply: true,
                method: 'POST',
            },
        },
        getDataFromResponse(response) {
            return response.data.data;
        }
    },

    methods: {
        canBeStartedBy(instructorId) {
            if (this.instructor_id == null) {
                return true;
            }
            if (this.instructor_id == instructorId) {
                return true;
            }
        },
        assignTo(instructorId) {
            this.instructor_id = instructorId;
            return this.http.assign();
        },
        complete(instructorId) {
            this.instructor_id = instructorId;
            return this.http.complete();
        },
        getStatusChangeTime() {
            if (this.status == 'ready') {
                return this.created_at || this.updated_at;
            }
            if (this.status == 'waiting') {
                return this.waiting_at || this.updated_at;
            }
            //instructors care more about when it was sent to them
            //and put into waiting status than when they assigned it to themselves
            if (this.status == 'assigned') {
                return this.waiting_at || this.updated_at;
            }
            if (this.status == 'completed_at') {
                return this.completed_at || this.updated_at;
            }
            return this.updated_at;
        },
        getBadgeColor() {
            switch (this.status) {
                case 'waiting': return 'badge-warning';
                case 'ready': return 'badge-default';
                case 'assigned': return 'badge-info';
                default: return 'badge-success';
            }
        },
    },
}
