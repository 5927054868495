<template>
  <transition name="slide-up">
          <div class="row" v-if="$data._student && $data._student != null">
            <div class="col-md-4">
              <button class="btn" @click="goBack">
                  <span class="btn-label">
                    <i class="material-icons">keyboard_arrow_left</i>
                  </span>
                  Back&nbsp;&nbsp;
              <div class="ripple-container"></div></button>


              <div class="card card-profile">
                <div class="card-avatar">
                  <a href="#">
                    <img class="img" @click="$emit('back')" height="48" width="48" :src="$data._student.pic_url">
                  </a>
                </div>
                <div class="card-body">
                  <h6 class="card-category text-gray"></h6>
                  <h4 class="card-title">{{$data._student.first_name}} {{$data._student.last_name}}</h4>
                  <p class="card-description">
                  </p>
                </div>
              </div>
            </div>

            <div class="col-md-8 pl-4">

              <ul class="timeline">
                    <li class="timeline-inverted" v-for="lesson in lessonList">
                      <div class="timeline-badge vos-red">
{{lesson.updated_at|dateFormat('MMM D')}}
                      </div>
                      <div class="timeline-panel">
                        <div class="timeline-heading">
                          <span class="badge badge-pill badge-danger">Lesson</span>
                          <span class="badge badge-pill" :class="lesson.getBadgeColor()">{{lesson.status | capitalize }}</span>

                        </div>
                        <div class="timeline-body">
                          <h6>
                            <i class="material-icons">calendar_today</i> {{lesson.updated_at|humanDateDiff}}
                          </h6>
                          <router-link :to="'/lessons/'+lesson.id">View</router-link>
                        </div>
                        <div class="timeline-footer">
                        <!--
                          <div class="dropdown">
                            <button type="button" class="btn btn-round btn-info dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                              <i class="material-icons">build</i>
                            <div class="ripple-container"></div></button>
                            <div class="dropdown-menu" x-placement="top-start" style="position: absolute; top: 3px; left: 1px; will-change: top, left;">
                              <a class="dropdown-item" href="#">Action</a>
                              <a class="dropdown-item" href="#">Another action</a>
                              <a class="dropdown-item" href="#">Something else here</a>
                            </div>
                          </div>
                          -->
                        </div>

                      </div>
                    </li>
                  </ul>

            </div>
          </div>
  </transition>
</template>
<script>

//const LessonModel = require('./lib/models/Lesson.js').default;
//VueModel.register('lesson', LessonModel);

export default {
//	props: ['student'],

    data: function() {
        return {
            _student: null,
            lessonList: [],
            loading: false,
            student: null,
        }
    },
    mounted: function() {
        //this.init();
    },

    methods: {
        init: function() {
            var self = this;
            if (this.student == null) {
                var studentLoader = this.$model('student', {instructor_id:this.$root.auth.getId(), id: this.$route.params.account_id});
                studentLoader.http.byInstructor()
                    .then(function(response) {
                        var studentList = self.$models('lesson', response.data.data);
                        self.getData(studentList[0]);
                        self.$data._student = studentList[0].attributes;
                    });
            } else {
                this.getData(this.student);
                this.$data._student = this.student;
            }
        },
        getData: function(theStudent) {
            var self = this;
            this.lessonList = [];
            var lesson = this.$model('lesson', {student_id: theStudent.id});
            lesson.http.studentHistory().
            then((response) => {
               self.lessonList = self.$models('lesson', response.data.data);
            });
        },
        goBack: function() {
            this.$emit('back');
            this.$router.go(-1);
        },
    },
    beforeRouteLeave (to, from, next) {
        this.$data._student = null;
        this.lessonList = [];
        next();
    },
    beforeRouteEnter (to, from, next) {
        next( function(vm) {
            vm.init();
        });
    },
}
</script>
<style>
.timeline {
    list-style: none;
    padding: 20px 0;
    position: relative;
    margin-top: 30px;
}
.timeline:before {
    top: 50px;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 3px;
    background-color: #e5e5e5;
/*    left: 50%; */
    margin-left: -1px;
}
.timeline>li {
    margin-right:52px;
    margin-bottom: 20px;
    position: relative;
}
.timeline>li:after, .timeline>li:before {
    content: " ";
    display: table;
}
.timeline>li:after {
    clear: both;
}
.timeline>li.timeline-inverted>.timeline-panel {
    margin-left:52px;
/*    float: right; */
    background-color: #fff;
}

.timeline>li>.timeline-panel {
    width: 100%;
    float: left;
    padding: 20px;
    margin-bottom: 20px;
    position: relative;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,.14);
    border-radius: 6px;
    color: rgba(0,0,0,.87);
    background: #fff;
}
.timeline>li>.timeline-badge {
    color: #fff;
    width: 50px;
/*    height: 50px; */
    line-height: 51px;
    font-size: 1.4em;
    text-align: center;
    position: absolute;
    top: 16px;
/*    left: 50%; */
    margin-left: -24px;
    z-index: 100;
/*
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
*/

    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}
.timeline>li>.timeline-badge.success {
    background-color: #4caf50;
    box-shadow: 0 4px 20px 0 rgba(0,0,0,.14), 0 7px 10px -5px rgba(67,244,54,.4);
}

.timeline>li>.timeline-badge.danger {
    background-color: #f44336;
    box-shadow: 0 4px 20px 0 rgba(0,0,0,.14), 0 7px 10px -5px rgba(244,67,54,.4);
}
.timeline>li.timeline-inverted>.timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
}

.timeline>li.timeline-inverted>.timeline-panel {
/*    float: right; */
    background-color: #fff;
}
.timeline>li>.timeline-panel {
    width: 100%;
    float: left;
    padding: 20px;
    margin-bottom: 20px;
    position: relative;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,.14);
    border-radius: 6px;
    color: rgba(0,0,0,.87);
    background: #fff;
}
.timeline>li>.timeline-panel:before {
    position: absolute;
    top: 26px;
    right: -15px;
    display: inline-block;
    border-top: 15px solid transparent;
    border-left: 15px solid #e4e4e4;
    border-right: 0 solid #e4e4e4;
    border-bottom: 15px solid transparent;
    content: " ";
}
.timeline>li.timeline-inverted>.timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
}

.timeline>li>.timeline-panel:after {
    position: absolute;
    top: 27px;
    right: -14px;
    display: inline-block;
    border-top: 14px solid transparent;
    border-left: 14px solid #fff;
    border-right: 0 solid #fff;
    border-bottom: 14px solid transparent;
    content: " ";
}


.slide-up-enter-active,
.slide-up-leave-active,

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition-duration: 0.5s;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  transform: translate(2em, 0);
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transform: translate(-2em, 0);
}
.slide-up-leave-active,
.slide-up-enter {
  opacity: 0;
  transform: translateY(-2em, 0);
}


</style>
