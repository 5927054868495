
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

const Promise = require("bluebird");
window.Promise = Promise;
global.Promise = Promise;

require('./bootstrap');

window.Vue = require('vue');
const VueRouter = require('vue-router').default;
//const VueBreadcrumbs = require('vue-breadcrumbs').default;
import VueBreadcrumbs from 'vue-breadcrumbs'

import VueSweetalert2 from 'vue-sweetalert2';

import moment from 'moment';

import auth from './lib/auth';
import academy from './lib/academy';
//let promise = academy.getAcademyCode();

import cart from './lib/cart';

import { EventBus } from './lib/event-bus.js';

var VueModel = require('vue-model').default;
Vue.use(VueModel, {
    model: require('./lib/VueModelCustom.js')
});
const AcademyModel = require('./lib/models/Academy.js').default;
VueModel.register('academy', AcademyModel);

const AcademyMarketModel = require('./lib/models/AcademyMarket.js').default;
VueModel.register('marketSettings', AcademyMarketModel);

const LessonModel = require('./lib/models/Lesson.js').default;
VueModel.register('lesson', LessonModel);

const StudentModel = require('./lib/models/Student.js').default;
VueModel.register('student', StudentModel);
VueModel.register('account', require('./lib/models/Account.js').default);
VueModel.register('student_profile', require('./lib/models/Account.js').default);

const Product = require('./lib/models/Product.js').default;
VueModel.register('product', Product);
/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

Vue.component('breadcrumbs',       require('./components/Breadcrumbs.vue').default);
Vue.component('inbox-videos',      require('./components/InboxVideos.vue').default);
Vue.component('lesson-table',      require('./components/LessonTable.vue').default);
Vue.component('lesson-table-inst', require('./components/instructor/LessonTable.vue').default);
Vue.component('data-table-inst',   require('./components/instructor/DataTable.vue').default);
Vue.component('news-feed',         require('./components/NewsFeed.vue').default);
Vue.component('student-home',      require('./components/StudentHome.vue').default);
Vue.component('cart-widget',       require('./components/CartWidget.vue').default);
Vue.component('cart-complete',     require('./components/CartComplete.vue').default);
Vue.component('instructor-list',   require('./components/InstructorList.vue').default);
Vue.component('card-stat',         require('./components/CardStat.vue').default);
Vue.component('instructor-home',   require('./pages/InstructorHome.vue').default);
Vue.component('notifications',     require('./components/Notifications.vue').default);

/*
const Academy = require('./pages/Academy.vue').default;
Vue.component('academy', Academy);

const Dashboard = require('./pages/Dashboard.vue').default;
Vue.component('dashboard', Dashboard);

const ProductPage = require('./components/ProductPage.vue').default;
Vue.component('products', ProductPage);

const AcademiesPage = require('./pages/AcademiesPage.vue').default;
Vue.component('academies', AcademiesPage);

const LessonsPage = require('./pages/LessonsPage.vue').default;
Vue.component('lessons', LessonsPage);

const LessonDetailPage = require('./pages/LessonDetailPage.vue').default;
Vue.component('lesson-detail', LessonDetailPage);

const VideosPage = require('./pages/VideosPage.vue').default;
Vue.component('videos', VideosPage);

const StudentsPage = require('./pages/StudentsPage.vue').default;
Vue.component('students', StudentsPage);

const StudentProfile = require('./pages/StudentProfile.vue').default;
Vue.component('student-profile', StudentProfile);
*/

Vue.use(VueSweetalert2);
Vue.use(VueRouter);
Vue.use(VueBreadcrumbs, {
    linkActiveClass: 'active',
    template: '<nav><a class="navbar-brand" v-html="$breadcrumbs[ $breadcrumbs.length-1].meta.breadcrumb">Dashboard</a></nav>',
    /*
    template: '<nav><ol class="breadcrumb" v-if="$breadcrumbs.length"> ' +
        '<li v-for="(crumb, key) in $breadcrumbs" v-if="key > $breadcrumbs.length-1" class="breadcrumb-item" ><router-link :to="linkProp(crumb)" :key="key">{{ crumb | crumbText }}</router-link> </li>' +
        '<li class="breadcrumb-item active" v-html="$breadcrumbs[ $breadcrumbs.length-1].meta.breadcrumb"></li>' +
        '</ol>' +
    '<h4 class="page-title" v-html="$breadcrumbs[ $breadcrumbs.length-1].meta.breadcrumb"></h4></nav>'
    */
});

const routes = require('./lib/routes').default;

export const router = new VueRouter({
    linkActiveClass: 'active',
    mode: 'history',
    routes // short for `routes: routes`
});

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

Vue.filter('capitalize', function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
});
Vue.filter("wholeNumber", function (value) {
    return Number.parseInt(value);
});
Vue.filter('timestamp', function (value) {
      if (!value) return -1
      value = moment.utc(value, 'YYYY-MM-DD HH:ii:ss');
      return value.format('X');
});
Vue.filter('moment-fromnow', function (value) {
    if (!value) return -1
    value = moment.utc(value, 'YYYY-MM-DD HH:mm:ss');//.utcOffset(hrs);
    return value.fromNow();
});
Vue.filter("humanDateDiff", function (value) {
    var hrs = -(new Date().getTimezoneOffset());
    return moment.utc(value).local().fromNow();
});
Vue.filter("datetimeFormat", function (value) {
    var hrs = -(new Date().getTimezoneOffset());
    return moment.utc(value).local().format("YYYY/MM/DD h:mm A z");
});
Vue.filter("dateFormat", function (value, dformat='YYYY/MM/DD') {
    var hrs = -(new Date().getTimezoneOffset());
    return moment.utc(value).local().format(dformat);
});

var formInitial = [];

var transientKeyHandler;
Vue.directive(
        'escape-key', {
            bind: function(el, binding, vnode) {
                transientKeyHandler = function(event) { if (event.keyCode == 27 || event.key == 'Escape') {binding.value.call(vnode.context, event);} };
                document.body.addEventListener('keyup', transientKeyHandler)
            },
            unbind: function(el) {
                document.body.removeEventListener('keyup', transientKeyHandler)
            },
        }
);
Vue.prototype.$apiBaseUrl = MIX_API_ACCOUNT_BASEURL+'/api201902/';
Vue.prototype.$api = window.axios.create({
    baseURL: MIX_API_ACCOUNT_BASEURL+'/api201902/',
    headers: {
        'Authorization': 'Bearer '+ auth.getJwtToken()
    }
});
Vue.prototype.$http = window.axios;

const app = new Vue({
    router,
    data: function() {
        return {
            breadcrumbs:[],
            academy: academy,
            auth: auth,
            lessonList:[],
            cart: cart,
        }
    },
    watch: {
        '$route': function()  {
            this.breadcrumbs = [{name: this.$route.meta.breadcrumb, to: this.$route.meta.to}]
        }
    },
    computed: {
        hasBrandedAcademy: function() {
            return !! this.academy.academyCode && Boolean((
                this.academy.academyCode
                && this.academy.brand['name']
                && typeof(this.academy.academyCode) != 'undefined'
                && this.academy.academyCode !== null
                && this.academy.academyCode != ''));
        },
        selectedAcademy: function() {
            return this.auth.academyCode;
        },
    },
    beforeMount: function() {
        //save all form inputs
        formInitial = $('form').serializeArray();
    },
    mounted: function() {
        //apply all form inputs
        formInitial.forEach( item => {
            $('form input[name='+item.name+']').val( item.value );
        });
        EventBus.$on('lessonsChanged', this.fetchLessons);
    },
    methods:{
        getLesson: function(id) {
            let foundLesson = this.lessonList.filter( (item) => {
                return item.id == id;
            });
            if (foundLesson.length) {
                return foundLesson[0];
            }
            console.log('cannot find lesson id ' + id);
            return null;
        },
        fetchLessons: function() {
            this.lessonList = [];
            return axios.get('/lessons/')
                .bind(this)
                .then(function(response) {
                    if (!response.data) { return; }
                    response.data.data.forEach( (l) => {
                        this.lessonList.push( this.$model('lesson', l));
                    });
                })
                .error (function (err) {
                    this.$swal({
                        text: "Cannot load products.", 
                        type:'error',
                    });
                    console.error(err);
                });
        },
        joinedAcademies: function() {
            return ['v1ac', 'shyg'];
        },
        switchAcademy: function(code) {
            this.academy.resetBrand();
            let promise = this.academy.enrollAcademy(code);
            promise.then((code) => {
                const bodybody = document.getElementsByTagName('body');
                Array.from(bodybody).forEach((bodyTag)=>{ bodyTag.setAttribute('data-acbc', code)});
            }).then(() => {
                return this.academy.getAcademyBrandXhr();
            }).then(() => {
                EventBus.$emit('selectedAcademy', code);
            });
        },
        getApiToken: function() {
            return this.getCookieValue('token');
        },
        getCookieValue: function(a) {
            var b = document.cookie.match('(^|[^;]+)\\s*' + a + '\\s*=\\s*([^;]+)');
            return b ? b.pop() : '';
        },
        isUserInst: function() {
            const bodybody = document.getElementsByTagName('body')
            let body = {};
            Array.from(bodybody).forEach((x)=>{ body = x});
            return (body.getAttribute('data-inst') == '1');
        },
    },
});
app.$mount('#main-wrapper');
window.app = app;

axios.interceptors.response.use(undefined, function (error) {
	let config = error.config || {};
	if (error.response.status === 401 && !config._retry) {
        app.$swal({
            title: "Session Expired",
            text: "Your session has expired. You will be redirected to the login page?",
            type: "warning",
            showCancelButton: false,
            confirmButtonText: "Login",
        }).then( function(result){
			if (result.value) {
	            window.location = '/login';
			}
		});

        //to stop any already attached "catch" methods
        //which may be attached for handling cases
        //which are not well known (401, 403, 404, etc)
        //we will throw a new promise and immediately 
        //swallow the error with an empty catch
	    return Promise.reject(error).catch(() => {} );
	}

	if (error.response.status === 403 && !config._retry) {
        app.$swal({
            title: "Permission Error",
            text: "Your account does not have permission to perform the requested operation.",
            type: "warning",
            showCancelButton: false,
            confirmButtonText: "Shoot :(",
		});

        //to stop any already attached "catch" methods
        //which may be attached for handling cases
        //which are not well known (401, 403, 404, etc)
        //we will throw a new promise and immediately 
        //swallow the error with an empty catch
	    return Promise.reject(error).catch(() => {} );
	}

	//continue with any attach catch()
	return Promise.reject(error);
});
