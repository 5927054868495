<template>
                <div class="card">
                    <div class="card-header card-header-vos">
                          <h4 class="card-title"><i class="material-icons">content_copy</i> Your Lesson {{lessonid}}</h4>
                    </div>
                    <div class="card-body">
                        <div class="mt-3" v-show="!loading && lesson.status == 'ready'">
                            <h3>Attach Videos</h3>
                            <div class="row">
                            <div style="max-width:125px;" class="mx-3 p-1 col-sm-3 col-6" v-for="attach in attachmentList">
                                <img class="card-img-top img-responsive"
                                    v-bind:src="attach.thumb_url"
                                    alt="Card image cap"
                                    @error="imageFailed">
                                    <h6 class="card-title"><span :title="attach.title">{{attach.title | first20Chars}}</span></h6>

                            </div>
                            <a href="#" @click.stop="$emit('openAttachments')">
                            <div style="border: 3px dashed gray; max-width:125px" class="mx-3 p-4 col-sm-3 col-6 text-center">
                            &nbsp;<i class="material-icons">attach_file</i>
                            </div>
                            </a>
                            </div>
                        </div>

                    </div>
                    <div class="card-body text-right" v-show="!this.loading && this.lesson.status == 'ready'">
                        <button class="btn btn-success" @click="sendLesson()" :disabled="attachmentCount==0 || this.lesson.http.updateInProgress">Send videos to instructor</button>
                    </div>


                </div>
</template>
<script>
import { EventBus } from '../../lib/event-bus.js';
export default {
    props: ['lesson', 'attachmentList', 'attachmentCount', 'loading', 'lessonid'],
    methods: {
        imageFailed: function(evt) {
            evt.target.src = '/dist/images/locker_placeholder.png';
        },
        sendLesson: function() {
            var self = this;
            this.$parent.loading = true;
            this.lesson.http.update().then(() => {
                self.$parent.loading = false;
                this.attachmentList = [];
                EventBus.$emit('lessonsChanged');
            },
            (err) => {
                self.$parent.loading = false;
            });
        },
    },
}
</script>
