<template>
    <div class="card">
                        <div class="overlay-spinner" v-show="notLoaded"><scale-loader :loading="notLoaded" :color="color"></scale-loader></div>
                        <div class="card-body">
                                <h4 class="card-title">{{title}} <span class="badge" :class="getBadgeColor(status)">{{status | capitalize }}</span></h4>
                                                

                                <div class="mt-3" v-show="notLoaded">
                                <h5>
                                    All caught up!
                                </h5>
                                </div>

                                <div class="table-responsive mt-3" v-show="notLoaded">
                                You don't have any lessons requests.
                                </div>
                                <div class="table-responsive mt-3">
                                    <table class="table table-striped" v-show="!notLoaded">
                                        <thead>
                                            <tr>
                                                <th>Student</th>
                                                <th>&nbsp;</th>
                                                <th class="text-nowrap">Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="lesson in dataPreview">
                                                <td><img height="28" width="28" :src="getStudentPic(lesson.user_id)"> {{getStudentName(lesson.user_id)}}</td>
                                                <td>
                                                    <router-link tag="button"
                                                        class="btn btn-default" type="button"
                                                        :to="'/lessons/'+lesson.id">View Lesson</router-link>

                                                    <button
                                                        type="button"
                                                        class="btn btn-outline"
                                                        v-if="lesson.status == 'assigned'" v-show="lesson.instructor_id == getUserId()"
                                                        :disabled="lesson.http.inProgress"
                                                        @click="completeLesson(lesson)">Complete</button>

                                                    <button  class="btn btn-success" type="button"
                                                        v-if="lesson.status =='waiting'"
                                                        v-show="lesson.canBeStartedBy(getUserId())"
                                                        :disabled="lesson.http.inProgress"
                                                        @click="assignLesson(lesson, getUserId())">Start Lesson</button>
                                                    <span v-show="lesson.instructor_id != getUserId()">
                                                    Someone else's lesson
                                                    </span>

                                                </td>

                                                </td>
                                                <td class="text-nowrap" :data-timestamp="lesson.getStatusChangeTime() | timestamp">
                                                {{lesson.getStatusChangeTime() | moment-fromnow}}
                                                </td>
                                            </tr>
                                            <tr v-show="dataRemainingCount">
                                                <td colspan="4"> Plus {{dataRemainingCount}} more ...</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                        </div>
</div>
</template>

<script>
import DataTable from './DataTable.vue';

export default {
    extends: DataTable,

    methods: {
        assignLesson: function(lesson, userId) {
            var self = this;
            lesson.assignTo(userId)
            .then(() => {
                self.$emit('lessonsChanged');
            })
        },
        completeLesson: function(lesson) {
            var self = this;
            lesson.http.complete()
            .then(() => {
                self.removeLesson(lesson);
                self.$emit('lessonsChanged');
            });
        },
        removeLesson: function(lesson) {
            this.data = this.data.filter( (item) => {
                return (item.id !== lesson.id);
            });
        },
    },
}
</script>
