import auth from '../auth';
export default {
    attributes: [
        'name',
        'academyCode',
        'logo',
        'banner_graphic',
        'banner_text',
        'logoFile',
        'bannerGraphicFile',
    ],

    http: {
        baseRoute: process.env.MIX_API_ACCOUNT_BASEURL+'/api201902/academies/',
        axios: function(config, key, definition, runtimeArgs) {
            config.headers = {Authorization: 'Bearer '+ auth.getJwtToken()};
            //config.headers = {'Content-Type': 'multipart/form-data'};
            return config;
        },
        actions: {
            // Don't expose the destroy action
            destroy: false,
            fetch: {
                route: '{academyCode}/branding',
            },
            update: {
                method: 'PATCH',
                route: '{academyCode}/branding',
            },
            uploadLogo: {
                method: 'POST',
                route: '{academyCode}/branding/logo',
                data: {
                    custom: function(data, definition) {
                        var fd = new FormData();
                        fd.append('logo', this.logoFile);
                        return fd;
                    }
                },
            },
            uploadBanner: {
                method: 'POST',
                route: '{academyCode}/branding/banner',
                data: {
                    custom: function(data, definition) {
                        var fd = new FormData();
                        fd.append('banner', this.bannerFile);
                        return fd;
                    }
                },
            },        },
        getDataFromResponse(response) {
            return response.data.data.attributes;
        }
    },
    methods: {
        getAxiosInstance() {
            return null;
        },
        updateLogo(file) {
            this.logoFile = file;
            return this.http.uploadLogo()
            .then(() => {
                this.logoFile = null;
            });
        },
        updateBanner(file) {
            this.bannerFile = file;
            return this.http.uploadBanner().then(() => {
                this.bannerFile = null;
            });

        },
    },

    getAxiosInstance() {
        return null;
    },
}
