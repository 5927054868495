<template>
    <div class="row">
        <div class="col-md-6 col-12">
            <div class="card" v-if="instructor">
                <div class="card-body">

                    <form method="">
                        <div class="form-group bmd-form-group" :class="{'is-filled': instructor.first_name}">
                          <label class="bmd-label-floating">First Name</label>
                          <input type="text" class="form-control" v-model="instructor.first_name">
                        </div>
                        <div class="form-group bmd-form-group" :class="{'is-filled': instructor.last_name}">
                          <label class="bmd-label-floating">Last Name</label>
                          <input type="text" class="form-control" v-model="instructor.last_name">
                        </div>
                        <div class="form-group bmd-form-group" :class="{'is-filled': instructor.title}">
                          <label class="bmd-label-floating">Title</label>
                          <input type="text" class="form-control" v-model="instructor.title">
                        </div>

                        <div class="form-group" :class="{'is-filled': instructor.bio, 'form-group-error': instructor.http.errors.has('bio') }">
                          <label
                            :class="{'text-danger': instructor.http.errors.has('bio') }"
                          >Bio</label>
                          <div class="form-group bmd-form-group">
                              <textarea class="form-control" rows="3"
                                  :class="{'form-control-danger': instructor.http.errors.has('bio') }"
                                  v-model="instructor.bio"
                              ></textarea>
                          </div>
                        </div>
                        <div class="form-group" :class="{'form-group-error': instructor.http.errors.has('accolades') }">
                          <label
                              :class="{'text-danger': instructor.http.errors.has('accolades') }">Accolades / Accomplishments</label>
                          <div class="form-group bmd-form-group">
                              <textarea
                                  class="form-control"
                                  :class="{'form-control-danger': instructor.http.errors.has('accolades') }"
                                  rows="3"
                                  v-model="instructor.accolades"
                              ></textarea>
                          </div>
                        </div>
                        <div class="form-group">
                          <label>Teaching Philosophy</label>
                          <div class="form-group bmd-form-group">
                              <textarea class="form-control" rows="3"
                                  v-model="instructor.philo"
                              ></textarea>
                          </div>
                        </div>
                        <div class="form-group">
                          <label>Head Shot</label>
                          <div class="form-group bmd-form-group">
                              <picture-input
                                  ref="pictureInput"
                                  @remove="onRemove"
                                  @change="onChange"
                                  width="128"
                                  height="128"
                                  margin="16"
                                  accept="image/jpeg,image/png"
                                  size="100"
                                  :removable="true"
                                  :customStrings="{
                                    upload: '<h1>Bummer! No file upload</h1>',
                                    drag: 'Drop Photo or Click'
                                  }">
                            </picture-input>
                          </div>
                        </div>

                        <div class="form-group">
                            <button 
                                class="btn btn-primary"
                                :disabled="this.instructor.http.inProgress || this.pictureProgress"
                                :class="{disabled: this.instructor.http.inProgress || this.pictureProgress}"
                                type="button"
                                @click="save"
                            >
                                <i class="fa fa-spinner fa-spin mr-2"
                                    v-if="this.instructor.http.inProgress || this.pictureProgress"
                                > </i>
                                Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-12">
            <instructor-profile
                :instructor="instructor"
                ref="profileCard"
            />
        </div>
    </div>
</template>
<script>

const VueModel = require('vue-model').default;
const InstProfileCard = require('../components/instructor/ProfileCard.vue').default;
const InstructorModel = require('../lib/models/Instructor.js').default;
import VueModelErrors from '../lib/VueModelErrors.js'
VueModel.register('instructor', InstructorModel);

import auth from '../lib/auth';

import PictureInput from 'vue-picture-input';

export default {
    components: {
        'instructor-profile': InstProfileCard,
        'picture-input': PictureInput,
    },
    mixins: [VueModelErrors],
    data: function() {
        return {
            instructor: this.$model('instructor', {'id': auth.getId()}),
            avatarData: '',
            pictureProgress: false,
        };
    },
    models: {
        listeners: {
            'instructor.update.error': 'vueModelApiError',
        },
    },
    computed: {
    },
    methods: {
        getData() {
            this.getInstructors();
        },
        getInstructors() {
            var self = this;
            this.instructor.http.fetch({'id': auth.getId()}).then( () => {

            });
        },
        save() {
            var self = this;
            this.instructor.http.update()
            .catch((err) => {
                console.log(err);
                if (!err.handled) {
                    self.$swal({
                        title: "Unknown error saving your profile",
                        type: "warning",
                        showCancelButton: false,
                        confirmButtonText: "OK",
                    });
                }
            });
            if (self.$refs.pictureInput.image) {
                var formData = new FormData();
                formData.append('headshot', self.$refs.pictureInput.file);
                self.pictureProgress = true;
                self.$api.post(
                    'instructors/' +auth.getId()+'/headshot',
                    formData,
                    {
                        'Content-Type': 'multipart/form-data',
                        'Media-Type': 'multipart/form-data',
                    }
                ).then( (response) => {
                    self.pictureProgress = false;
                    self.instructor.profile_pic = response.data.data.attributes.profile_pic;
                    self.$refs.profileCard.randomNum = (Math.random()*123);
                });
            }
        },
        onRemove () {
            this.avatarData = '';
        },
        onChange () {
            if (this.$refs.pictureInput.image) {
                console.log('Picture loaded.')
            } else {
                console.log('FileReader API not supported: use the <form>, Luke!')
            }
        },
    },
    mounted: function() {
        this.getData();
    },
};
</script>
