<template>


<div>
            <div
                v-if="useFiltering"
                class="d-flex mb-3 justify-content-center btn-group"
                style="margin-bottom:-10px"
            >
		
                <div class="btn-group" data-toggle="buttons">
                    <button @click.prevent="filter='current'" class="btn btn-round" :class="{'btn-primary': filter=='current', 'btn-secondary': filter == 'all'}" style="font-size:90%;padding-top:5px;padding-bottom:5px;"><span >Recent</span></button>
                    <button @click.prevent="filter='all'" class="btn btn-round" :class="{'btn-primary': filter=='all', 'btn-secondary': filter == 'current'}" style="font-size:90%;padding-top:5px;padding-bottom:5px;"><span>All</span></button>
                </div>
            </div>
                    <div class="card">
                            <div class="overlay-spinner" v-show="loading"><scale-loader :loading="loading" :color="color"></scale-loader></div>
                            <div class="card-body">

                                <div class="mt-3" v-show="!loading && noLessons">
<h3>Want personalized lessons?</h3>
<img src="https://v1sports.com/wp-content/uploads/2022/06/PR_V1_Golf_Images_2-2.png" height="200">

<h4>
Upload your swing captures to get pro analysis and instruction.
</h4>
</div>

                                <div class="table-responsive mt-3" v-show="!loading && noLessons">
                                You don't have any lessons.
                                </div>
                                <div class="table-responsive mt-3">
                                    <table class="table table-striped" v-show="!notLoaded">
                                        <thead>
                                            <tr>
                                                <th>Academy</th>
                                                <th>Status</th>
                                                <th class="text-nowrap">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="lesson in lessonListPreview">
                                                <td>{{getAcademyName(lesson)}}</td>
                                                <td>
                                                <span class="badge" :class="getBadgeColor(lesson)">{{lesson.status | capitalize }}</span>
                                                </td>
                                                <td class="text-nowrap">
                                                    <router-link v-show="lesson.status == 'ready'" :to="'/lessons/'+lesson.id" href="#" class="btn waves-effect waves-light btn-success" data-toggle="tooltip" data-original-title="Send"><i class="material-icons">input</i> Start Lesson</router-link>
                                                    <router-link v-show="lesson.status !== 'ready'" :to="'/lessons/'+lesson.id" href="#" class="btn waves-effect waves-light btn-default" data-toggle="tooltip" data-original-title="Send"><i class="material-icons">input</i> View Lesson</router-link>
                                                </td>
                                            </tr>
                                            <tr v-show="lessonListRemainingCount">
                                                <td colspan="4">
                                                    <router-link to="/lessons">
                                                        Plus {{lessonListRemainingCount}} more ...
                                                    </router-link>
                                                </td>
                                            </tr>

                                        </tbody>
                                        <tfoot>
                                            <a
                                                href="https://www.youtube.com/watch?v=9BuIfKGs1yI&feature=youtu.be"
                                                target="_blank"
                                                @click.prevent="showHelpVideo('https://www.youtube.com/embed/9BuIfKGs1yI')"
                                            >How do I redeem a lesson? (Watch video)</a>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>


    <div class="modal fade" tabindex="-1" role="dialog" id="player-modal" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>


</div>
</template>

<script>
import moment from 'moment';
var ScaleLoader = require('vue-spinner/src/ScaleLoader.vue').default;

export default {
    components: {
        'scale-loader': ScaleLoader
    },
    props: {
        previewLimit: {
            type: Number,
            default: 5,
        },
        useFiltering: {
            type: Boolean,
            default: true,
        },
    },
    data: function() {
        return {
            notLoaded: true,
            loading:   true,
            color: '#CC2343',
            filter: 'current',
            lessonList: [],
            academyList: [],
            instructorList: [],
            playerOptions: {
                // videojs options
                muted: true,
                language: 'en',
                playbackRates: [0.50, 0.75, 1.0, 1.5, 2.0],
                sources: [{
                    src: "https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm"
                }],
                poster: "/static/images/author.jpg",
            },
        }
    },
    computed: {
        filteredLessonList: function() {
            if (this.useFiltering == false) {
                return this.lessonList;
            }
            //current is just recent
            if (this.filter == 'current') {
                var counter = 0;
                var momentNow = moment.utc();
                return this.lessonList.filter((item)=>{
                    var value = moment.utc(item.getStatusChangeTime(), 'YYYY-MM-DD HH:ii:ss');
                    return value.diff(momentNow, 'days') < 15 && (counter++ < 5);
                });

            }
            return this.lessonList;
        },
        noLessons: function() {
            return this.filteredLessonList.length < 1;
        },
        lessonListPreview: function() {
            return this.filteredLessonList.slice(0, this.previewLimit);
        },
        lessonListRemainingCount: function() {
            if (this.filteredLessonList.length <= this.previewLimit ) {
              return false;
            }
            return this.filteredLessonList.length - this.previewLimit;
        },
    },
    mounted: function() {
        var self = this;
        this.fetchLessons().then( () => {
            self.notLoaded = false;
            self.loading   = false;
        });
    /*
        var prom = axios.get('/lessons/')
            .bind(this)
            .then(function(response) {
                if (!response.data) { return; }
                response.data.data.forEach( (l) => {
                    this.$root.lessonList.push( this.$model('lesson', 
                    Object.assign({id: l.id}, l.attributes)
                    ));
                });
                this.$root.lessonList = response.data.data || [];

                if (self.$root.lessonList.length > 0) {
                    self.notLoaded = false;
                    self.loading   = false;
                }
            })
            .error (function (err) {
                self.$swal({
                    text: "Cannot load products.", 
                    type:'error',
                });
                console.error(err);
            });
            */
		$('#player-modal').on('hide.bs.modal', function (e) {
            try {
                $('#player-modal iframe').remove();
                //self.$refs.videoPlayer.player.pause();
            } catch (e) {
                //player.tech is not ready
            }
		});
    },
    beforeCreate: function() {
    },
    methods: {
        fetchLessons: function() {
            this.lessonList  = [];
            this.academyList = [];
            this.instructorList = [];
            return axios.get('/lessons/')
                .bind(this)
                .then(function(response) {
                    if (!response.data) { return; }
                    response.data.data.forEach( (l) => {
                        this.lessonList.push( this.$model('lesson', l));
                    });
                    if (response.data.included) {
                        this.academyList = response.data.included.filter( (item) => {
                            return item.type == 'academy';
                        });
                        this.instructorList = response.data.included.filter( (item) => {
                            return item.type == 'instructor';
                        });
                    }
                })
                .error (function (err) {
                    this.$swal({
                        text: "Cannot load products.", 
                        type:'error',
                    });
                    console.error(err);
                });
        },
        getAcademyName(lesson) {
            if (lesson.instructor_id) {
                const inst = this.instructorList.find((item) => {
                    return item.id == lesson.instructor_id;
                });
                if (inst) {
                    return inst.attributes.first_name + ' ' + inst.attributes.last_name;
                }
            }
            if (lesson.academy_code) {
                return lesson.academy_code;
            }
        },
        showHelpVideo(url) {
            $('#player-modal').modal('show');

            $('#player-modal .modal-body').append('<iframe width="560" height="315" src="'+url+'?enablejsapi=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>');
            $('#player-modal iframe').attr('width', '100%');
        },
        getBadgeColor: (lesson) => {
            switch (lesson.status) {
                case 'waiting': return 'badge-warning';
                case 'ready': return 'badge-default';
                case 'assigned': return 'badge-info';
                default: return 'badge-success';
            }
        },
    },
}
</script>
