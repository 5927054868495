<script>

export default {
    methods: {
        thumbnailFailed: function(evt) {
            if (evt.target.getAttribute('data-failed')) {
                return this.imageFailedFinal (evt);
            }
            evt.target.src = '/dist/images/locker_placeholder.png';
            evt.target.setAttribute('data-failed', 'true');
            var stagger = Math.floor(Math.random() * (1800 - 400 + 1)) + 400;

            //FIXME: don't hardcode in a static ip for thumbnailer
            //stagger load because this is not load balanced
            window.setTimeout(
            () => {
                evt.target.src="https://vthumb.do.v1sports.com/?fmt=jpg&url="+evt.target.getAttribute('data-video-url');
            },stagger);
        },
        imageFailedFinal: function(evt) {
            console.log('final image failed');

            if (evt.target.src == '/dist/images/locker_placeholder.png') {
                return;
            }
            evt.target.src = '/dist/images/locker_placeholder.png';
        },
    },
};
</script>
