<template>
<div>
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Checkout</h4>
                <table class="table">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Lessons Included</th>
                        <th align="right" style="text-align:right">Price</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="product in cart.getProducts()">
                        <td>{{displayProductName(product)}}
                            <span v-if="product.prod.instructor_id">
                                &mdash; {{displayInstructorName(product)}}
                            </span>
                            <br/>
                            <a 
                                href="#" 
                                class="text-secondary"
                                @click.prevent="removeItem(product.prod)"
                            ><i class="remove-icon material-icons" aria-hidden="true">close</i> remove item</a>
                        </td>
                        <td>{{product.prod.credits}}</td>
                        <td align="right">${{product.prod.price | priceFormat}}</td>
                    </tr>
                    <tr v-if="cart.handling > 0">
                        <td colspan="1"></td>
                        <td align="right">Online Handling Fee</td>
                        <td align="right">${{cart.handling | priceFormat}}</td>
                    </tr>
                    <tr>
                        <td colspan="1"></td>
                        <td align="right">Total</td>
                        <td align="right">${{showCartTotal() | priceFormat}}</td>
                    </tr>

                    </tbody>
                </table>

                <button class="btn btn-secondary btn-outline pull-right text-muted" id="btn__cart__cancel" @click="$emit('cartCanceled')"><i class="fa fa-arrow-left"></i> Back</button>
        </div>
    </div>
        <div class="card card__login" v-if="!$root.$data.auth.user.authenticated">
            <div class="card-body">

                        <div id="loginform">
                            <div class="alert bg-secondary text-white" v-show="errorMessage">{{errorMessage}}</div>
                            <form class="form-horizontal mt-3" id="loginform" method="POST">
                                <div class="bmd-form-group input-group mb-3">
                                    <input type="text" name='email' class="form-control form-control-lg" placeholder="Email"
                                        :class="{'is-invalid': errorMessage}"
                                        aria-label="Email" aria-describedby="basic-addon1" v-model="email">
                                            <label class="text-danger" v-show="errorMessage">{{validation.email[0]}}</label>
                                </div>
                                <div class="bmd-form-group input-group mb-3">
                                    <input type="password" name='password' class="form-control form-control-lg" placeholder="Password"
                                        :class="{'is-invalid': errorMessage}"
                                        aria-label="Password" aria-describedby="basic-addon1" v-model="password">
                                            <label class="text-danger" v-show="errorMessage">{{validation.password[0]}}</label>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-12">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="customCheck1">
                                            <label class="custom-control-label" for="customCheck1">Remember me</label>
                                            <a href="javascript:void(0)" id="to-recover" class="text-dark float-right"><i
                                                    class="fa fa-lock m-r-5"></i> Forgot pwd?</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group text-center">
                                    <div class="col-xs-12 p-b-20">
                                        <button class="btn btn-lg btn-success text-white" id="btn__cart__login" type="submit" @click.prevent="login" :disabled="loginProgress"><i class="fa fa-refresh icon__loading__busy" v-show="loginProgress"></i>Log In</button>
                                    </div>
                                </div>
                                <div class="form-group m-b-0 m-t-10">
                                    <div class="col-sm-12 text-center">
                                        Don't have an account? <a href="register" class="text-info m-l-5">
                                            <b>Sign Up</b></a>
                                    </div>
                                </div>
                            </form>
                       </div>



            </div>
        </div>
        <div class="card" v-show="$root.$data.auth.user.authenticated">
            <div class="card-body">
                <div id="card-errors" class="alert bg-secondary text-white hide d-none" role="alert" aria-role="alert"></div>

                <form id="payment-form" method="post" action="/order-intents">
                    <div class="row">
                        <div class="col">
                            <label for="name">Name on Card</label>
                            <div class="input-group mb-2">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="fa fa-user" aria-hidden="true"></i></span>
                                </div>
                                <input type="text" class="form-control" id="card-name" name="card-name" value="">
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <label for="card-number">Credit Card Number</label>
                            <div class="input-group mb-2">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="fa fa-credit-card" aria-hidden="true"></i></span>
                                </div>
                                <div type="text" value="4242424242424242" id="card-number" class="form-control"></div>
                            </div>
                        </div>
                        <div class="col">
                            <label for="card-exp">Expiration</label>
                            <div class="input-group mb-2">
                                <div id="card-exp" class="form-control">
                                    <!-- Stripe Card Expiry Element -->
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <label for="card-cvc">CVC Number</label>
                            <div class="input-group mb-2">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">CVC</span>
                                </div>

                                <span id="card-cvc" class="form-control">
                                    <!-- Stripe CVC Element -->
                                </span>
                            </div>
                        </div>
                        <div class="col">
                            <label for="card-cvc">ZIP (Post Code)</label>
                            <div class="input-group mb-2">
                                <div class="input-group mb-2">
                                    <input type="text" class="form-control" id="card-zip" name="card-zip">
                                </div>
                            </div>
                        </div>
                    </div>
                    <button id="payment-submit" class="btn btn-primary bg-gradient-primary mt-1" type="submit" :disabled="this.checkoutProgress" :class="{disabled: this.checkoutProgress}"><i class="fa fa-spinner fa-spin mr-2" v-if="this.checkoutProgress"> </i>Submit Payment</button>
                </form>
            </div>
        </div>


</div>

</template>

<script>
Vue.filter("priceFormat", function (value) {
    return Number.parseFloat(value).toFixed(2);
});
import axios from 'axios';
export default {
   props: ['cart', 'connectedAccountId', 'instructors'],
    data: function() {
        return {
            email: '',
            password: '',
            loginProgress: false,
            errorMessage: '',
            validation: {'email':[''], 'password':['']},
            checkoutProgress: false,
            paymentIntentSecret: '',
            paymentMethod: null,
            customer: null,
            stripeClient: null,
        }
    },
    methods: {
        login: function() {
            this.errorMessage = '';
            this.validation =  {'email':[''], 'password':['']};
            this.loginProgress = true;
            this.$root.$data.auth.login(this, {'email': this.email, 'password': this.password})
                .bind(this)
                .then( null, (xhr) => {
                    this.errorMessage = 'Login failed';
                    if (xhr.response.data && xhr.response.data.errors) {
                        this.validation = Object.assign(this.validation, xhr.response.data.errors);
                    } else {
                        this.errorMessage = 'Email and password do not match our records.';
                    }
                })
                .finally(()=> {
                    this.loginProgress = false;
                });
        },
        removeItem: function(prod) {
            var self = this;

            //const params = new URLSearchParams();
            const params = {
                ac: this.$root.academy.academyCode,
                iid: prod.instructor_id
            };
            for (var i in prod) {
                params[i] = prod[i];
            }

            self.prodAddLoading = true;
            axios.post('/order/removeItem', params)
            .then( function(response) {
//                self.recentlyAdded = prod;

                self.cart.clear();
                response.data.data.products.forEach( prod => {
                    self.cart.addProduct( { prod: prod, qty: 1} );
                });
                self.cart.handling = response.data.data.handling;
                self.prodAddLoading = false;

                self.recordProductRemoved(prod);
            }).then( function() {
                window.setTimeout( function() { self.recentlyAdded=false}, 2200);
            });

        },
        recordProductRemoved: function(prod) {
            var _paq = window._paq = window._paq || [];
            _paq.push(['trackEvent', 'Ecommerce', 'removecart', prod.name]);
            _paq.push(['removeEcommerceItem', prod.code]);
            _paq.push(['trackEcommerceCartUpdate', this.cart.subTotal()]);
        },
        showCartTotal: function() {
            var tot = 0;
            var products = this.cart.getProducts();
            products.forEach( item => {
                tot += Number.parseInt(item.prod.price);
            });
            tot += Number.parseFloat(this.cart.handling);

            return tot; //0.00';
        },
        clearError: function(msg) {
          var displayError = document.getElementById('card-errors');
          $('#card-errors').addClass('hide');
          $('#card-errors').addClass('d-none');
          displayError.textContent = '';
        },
        showError: function(msg) {
          var displayError = document.getElementById('card-errors');
          displayError.textContent = msg;
          $('#card-errors').removeClass('hide');
          $('#card-errors').removeClass('d-none');
        },
        createCustomerAsync: async function(stripe, params) {
          var self = this;
          if (self.customer == null) {
            var params = {
              payment_method: self.paymentMethod,
              billing_details: {
                name: $('#card-name').val(),
                address: {
                  postal_code: $('#card-zip').val()
                },
              },
              _token: axios.defaults.headers["common"]['X-CSRF-TOKEN'],
              ac: this.$root.academy.academyCode,
            };

            return axios.post('/order-customer',
              params
            ).then( (response) => {
              if (response.data.data) {
                return response.data.data;
              }
              self.checkoutProgress = false;
              self.showError("Unable to fetch customer record.");
              throw new Error("Unable to fetch customer record.");
            }).then( (customer) => {
              self.customer = customer;
              return customer;
            }).catch( (error) => {
              self.checkoutProgress = false;
              throw new Error(error.response.data.message);
            });
          }
        },
        createPaymentMethodAsync: async function(stripe, params) {
          var self = this;
          if (self.paymentMethod == null) {
            return stripe
              .createPaymentMethod({
                type: 'card',
                card: card,
                billing_details: {
                  name: $('#card-name').val(),
                  address: {
                    postal_code: $('#card-zip').val()
                  },
                },
              }).then(result => {
                if (result.error) {
                  self.checkoutProgress = false;
                  throw result.error;
                }
                return result
              }).then(result => {
                self.paymentMethod = result.paymentMethod
              });
          }
        },
        submitOrderAsync: async function(stripe, params) {
            var self = this;
            await self.createPaymentMethodAsync(stripe, params)
                .then(result => {
                    return self.createCustomerAsync(stripe, params)
                        .then(result => {
                            return self.createOrderIntents(stripe, params)
                        }, errMessage => {
                            //allow for changing a card/payment method
                            self.paymentMethod = null;
                            self.showError(errMessage);
                        })
                }, error => {
                    self.showError(error.message);
                });
        },
        createOrderIntents: async function(stripe, params) {
          var self = this;
          params.customer_id = self.customer.id;
          axios.post('/order-intents', params).then( (response) => {
            var order = response.data.data;
            if (order.payment_intent) {
              self.paymentIntentSecret = order.payment_intent
              stripe.confirmCardPayment(self.paymentIntentSecret, {
                payment_method: {
                  card: card,
                  billing_details: {
                    name: $('#card-name').val()
                  }
                }
              }).then(function(response) {
                self.checkoutProgress = false;

                if (response.error) {
                  self.showError(response.error.message);
                }
                if (!response.error) {
                  self.submitOrder(stripe, params);
                }
              });
            } else {
              self.$emit('orderComplete', order);
              self.checkoutProgress = false;
            }
          }, (xhr) => {
              self.checkoutProgress = false;
              var errorElement = document.getElementById('card-errors');
              $('#card-errors').removeClass('hide');
              $('#card-errors').removeClass('d-none');
              errorElement.textContent = 'Order could not be finished. (' + xhr.response.data.message+')';
          });
        },
        submitOrder: function(stripe, params) {
            var self = this;
            self.checkoutProgress = true;
            // create customer
            // create payment method from Card Element
            // submit customer and pm to our backend
            // set as customer default
            // submit order to get payment intent
            // confirm pament intent
            self.submitOrderAsync(this.stripeClient, params);
        },
        setup: function() {
            let self = this;
            this.stripeClient = Stripe(process.env.MIX_STRIPE_PUBLIC_MARKETPLACE, {
              stripeAccount: this.connectedAccountId ? this.connectedAccountId: '',
            });

            // Create an instance of Elements
            var elements = this.stripeClient.elements();

            // Try to match bootstrap 4 styling
            var style = {
                base: {
                    'lineHeight': '1.35',
                    'fontSize': '1.11rem',
                    'color': '#495057',
                    'fontFamily': 'apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif',
                    ':focus': {
                        color:'#303238',
                    },
                },
            };

            // Card number
            var card = elements.create('cardNumber', {
                'placeholder': '',
                'style': style
            });
            card.mount('#card-number');
            window.card = card;

            // CVC
            var cvc = elements.create('cardCvc', {
                'placeholder': '',
                'style': style
            });
            cvc.mount('#card-cvc');

            // Card expiry
            var exp = elements.create('cardExpiry', {
                'placeholder': 'MM/YY',
                'style': style
            });
            exp.mount('#card-exp');

            card.addEventListener('change', function(event) {
                var displayError = document.getElementById('card-errors');
                if (event.error && event.error.message != '') {
                    displayError.textContent = event.error.message;
                    $('#card-errors').removeClass('hide');
                    $('#card-errors').removeClass('d-none');
                } else {
                    $('#card-errors').addClass('hide');
                    $('#card-errors').addClass('d-none');
                    displayError.textContent = '';
                }
            });
            exp.addEventListener('change', function(event) {
                var displayError = document.getElementById('card-errors');
                if (event.error && event.error.message != '') {
                    displayError.textContent = event.error.message;
                    $('#card-errors').removeClass('hide');
                    $('#card-errors').removeClass('d-none');
                } else {
                    $('#card-errors').addClass('hide');
                    $('#card-errors').addClass('d-none');
                    displayError.textContent = '';
                }
            });
            cvc.addEventListener('change', function(event) {
                var displayError = document.getElementById('card-errors');
                if (event.error && event.error.message != '') {
                    displayError.textContent = event.error.message;
                    $('#card-errors').removeClass('hide');
                    $('#card-errors').removeClass('d-none');
                } else {
                    $('#card-errors').addClass('hide');
                    $('#card-errors').addClass('d-none');
                    displayError.textContent = '';
                }
            });
            var form = document.getElementById('payment-form');
            form.addEventListener('submit', function(event) {
              self.clearError();
              self.checkoutProgress = true;
              event.preventDefault();

              try {
                var params = {
                    '_token': axios.defaults.headers["common"]['X-CSRF-TOKEN'],
                    'ac': self.$root.academy.academyCode,
                    'id': self.cart.id,
                };
                self.submitOrder(self.stripeClient, params);
              } catch (e) {
                self.showError(e);
                self.checkoutProgress = false;
              }
            });
        },
        setupStripe() {
            if (!window.Stripe) {
                let stripeScript = document.createElement('script')
                stripeScript.setAttribute('src', 'https://js.stripe.com/v3/')
                stripeScript.onreadystatechange = this.setup;
                stripeScript.onload             = this.setup;
                document.head.appendChild(stripeScript)
            } else {
                //attach stripe to form inputs for validation, etc.
                this.setup();
            }
        },
        displayProductName(product) {
            return product.prod.name;
        },
        displayInstructorName(product) {
            const instructor = this.instructors.find(item => item.id == product.prod.instructor_id);
            return instructor.first_name + ' ' + instructor.last_name;
        },
   },
}
</script>
<style>
.remove-icon {
    font-weight: 600;
    font-size: 16px;
}
#payment-submit {
/*     position:absolute; */
/*    z-index:10; */
}
</style>
