<template>
<div class="card">
                            <div class="overlay-spinner" v-show="loading"><scale-loader :loading="loading" :color="color"></scale-loader></div>
                            <div class="card-body">
                                <h4 class="card-title">Purchased Lessons</h4>

                                <div class="mt-3" v-show="notLoaded">
                                <h5>
                                All caught up!
                                </h5>
                                </div>

                                <div class="table-responsive mt-3" v-show="notLoaded">
                                You don't have any lessons requests.
                                </div>
                                <div class="table-responsive mt-3">
                                    <table class="table table-striped" v-show="!notLoaded">
                                        <thead>
                                            <tr>
                                                <th>Student</th>
                                                <th>Status</th>
                                                <th>Instructor</th>
                                                <th class="text-nowrap">Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="lesson in lessonListPreview">
                                                <td>{{getStudentName(lesson.user_id)}}</td>
                                                <td>
                                                <span class="badge" :class="getBadgeColor(lesson)">{{lesson.status | capitalize }}</span>
                                                </td>
                                                <td v-show="lesson.status == 'ready'">
                                                    <span v-show="lesson.instructor_id == null">Any</span>
                                                    <span v-show="lesson.instructor_id == getUserId()">
                                                    Your lesson
                                                    </span>
                                                    <span v-show="lesson.instructor_id !== null && lesson.instructor_id !== getUserId()">
                                                    Lesson belongs to instructor id {{lesson.instructor_id}}
                                                   your id: {{getUserId()}}
                                                    </span>
                                                </td>
                                                <td v-show="lesson.status == 'waiting'">
                                                    <button  class="btn btn-success" v-show="lesson.instructor_id == null" type="button" :disabled="lesson.http.inProgress" @click="lesson.assignTo(getUserId())">Start Lesson</button>
                                                    <span v-show="lesson.instructor_id !== null && lesson.instructor_id !== getUserId()">
                                                    Lesson belongs to instructor id {{lesson.instructor_id}}
                                                    </span>
                                                    <span v-show="lesson.instructor_id == getUserId()">
                                                    Your lesson
                                                    </span>

                                                </td>
                                                <td v-show="lesson.status == 'assigned'">
                                                    <button  class="btn btn-default" v-show="lesson.instructor_id == getUserId()" type="button" :disabled="lesson.http.inProgress" @click="completeLesson(lesson)">Finish Lesson</button>
                                                    <span v-show="lesson.instructor_id != getUserId()">
                                                    Lesson belongs to instructor id {{lesson.instructor_id}}
                                                    </span>

                                                </td>
                                                <td v-show="lesson.status !== 'waiting' && lesson.status !== 'ready' && lesson.status != 'assigned'">
                                                    {{lesson.status}}
                                                </td>
                                                <td class="text-nowrap" :data-timestamp="lesson.getStatusChangeTime() | timestamp">
                                                {{lesson.getStatusChangeTime() | moment-fromnow}}
                                                </td>
                                            </tr>
                                            <tr v-show="lessonListRemainingCount">
                                                <td colspan="4"> Plus {{lessonListRemainingCount}} more ...</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

</template>

<script>
import axios from 'axios';
import auth from '../../lib/auth';
var ScaleLoader = require('vue-spinner/src/ScaleLoader.vue').default;

Vue.filter("wholeNumber", function (value) {
    return Number.parseInt(value);
});

export default {
    components: {
        'scale-loader': ScaleLoader
    },
    data: function() {
        return {
            lessonList: [],
            studentList: [],
            notLoaded: true,
            color: '#CC2343',
        }
    },
    computed: {
        lessonListPreview: function() {
            return this.lessonList.slice(0, 5);
        },
        lessonListRemainingCount: function() {
            if (this.lessonList.length <= 5 ) {
              return false;
            }
            return this.lessonList.length - 5;
        },
        userId: function() {
            return auth.getId();
        },
    },
    methods: {
        getUserId: function() {
            return auth.getId();
        },
        getStudentName: function(userId) {
            let student = this.studentList.filter(function(item) {
                if (item.id == userId) return item;
            });
            if (student.length < 1) {
                return 'N/A';
            }
            student = student[0] || {};
            return student.attributes['last_name'] + ', ' + student.attributes['first_name'];
        },
        getBadgeColor: (lesson) => {
            switch (lesson.status) {
                case 'waiting': return 'badge-warning';
                case 'ready': return 'badge-default';
                case 'assigned': return 'badge-info';
                default: return 'badge-success';
            }
        },
        removeLesson: function(lesson) {
            this.lessonList = this.lessonList.filter( (item) => {
                return (item.id !== lesson.id);
            });
        },
        completeLesson: function(lesson) {
            var self = this;
            lesson.http.complete()
            .then(() => {
                self.removeLesson(lesson);
            });
        },
        getData: function() {
            axios.get('/lessons/scheduled')
                .then( (response) => {
                    this.studentList = response.data.included.data || [];
                    this.lessonList = response.data.data || [];
                    this.lessonList = this.$models('lesson', this.lessonList);

                    if (this.lessonList.length > 0) {
                        this.notLoaded = false;
    //                    this.$root.$nextTick(function() { window.feather.replace(); } );
                    }
                })
                .error (function (err) {
                    this.$swal({
                        text: "Cannot load lessons.", 
                        type:'error',
                    });
                    console.error(err);
                });
        },
    },
    mounted: function() {
        this.getData();
    },
}
</script>
