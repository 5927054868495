<script>

export default {
    data: function() {
        return {
            url: '',
            itemModel: '',
            itemList: [],
            includedList: [],
            loaded: false,
        }
    },
    methods: {
        getData: function(apiServer='') {
            var httpObj = this.$api;

            if (apiServer == 'market') {
                httpObj = this.$http;
            }
            if (this.loaded == true) {
                return;
            }
            var self = this;
            httpObj.get(this.url)
                .then( function(response) {
                    self.itemList = [];
                    response.data.data.forEach((item) => {
                        //TODO: fix some responses don't have type and attributes
                        self.itemList.push(
                            self.$model( (self.itemModel != '' ? self.itemModel : item.type), Object.assign(item.attributes || item, {id: item.id}))
                        );
                    });
                    if (response.data.meta) {
                        self.itemTotal = response.data.meta.total || self.itemList.length;
                    } else {
                        self.itemTotal = self.itemList.length;
                    }
                    self.loaded = true;

                    if (response.data.included) {
                        self.includedList = [];
                        response.data.included.forEach((item) => {
                            self.includedList.push(
                                self.$model( (item.type), Object.assign(item.attributes , {id: item.id}))
                            );
                        });
                    }
                })
                .error (function (err) {
                    self.$swal({
                        text: "Cannot load students.",
                        type:'error',
                    });
                    console.error(err);
                });
        },
    },
}
</script>
