<template>
<keep-alive>
<component :is="thecomponent" v-if="thecomponent"></component>
</keep-alive>
</template>

<script>
import InstructorHome from './InstructorHome.vue';
import StudentHome from '../components/StudentHome.vue';
export default {
    data: function() {
        return {
            thecomponent: null,
        }
    },
    mounted: function() {
        if (this.$root.isUserInst()) {
            this.thecomponent = InstructorHome;
        } else {
            this.thecomponent = StudentHome;
        }
    }
}
</script>
