<template>
    <div class="card">
        <div class="card-body" style="padding:0.55rem;">
            <div
                v-if="profilePic"
                class="img-raised rounded-circle img-fluid pull-left"
                :style="'max-width:125px; max-height:125px; width:125px; height:125px; background-image:url('+profilePic+');background-size:cover; background-position:center;margin-bottom:20px;'"
            ></div>
            <div style="margin-left:145px;">
                <h3 class="card-title" v-html="title"></h3>
                <h5 class="" v-if="inst && inst.title">
                    {{inst.title}}
                </h5>
                <p v-if="inst && inst.bio">
                    {{inst.bio}}
                </p>
            </div>

            <div
                class="el-card-item align-items-center d-flex"
                v-for="(prod, prodIndex) in productList"
                v-bind:key="prodIndex"
            >
                <div class="d-flex" style="flex-basis:5%">
                    <div style="width:60px;" class="media">
                        <img
                            :src="prod.getImage()"
                            alt="video lesson"
                        />
                    </div>
                </div>
                <div
                    class="d-flex no-block align-items-center flex-md-row flex-column"
                    style="flex-basis:15%"
                >
                    <div class="form-group">
                        {{prod.display_name}}
                    </div>
                </div>
                <div
                    class="d-flex no-block align-items-center flex-md-row flex-column justify-content-center"
                    style="flex-basis:3%"
                >
                    <div class="form-group">
                        {{prod.credits}}
                    </div>
                </div>
                <div
                    class="d-flex no-block align-items-center flex-md-row flex-column"
                    style="flex-basis:12%"
                >
                    <div class="form-group">
                        $ {{prod.price}}
                    </div>
                </div>

                <div class="d-flex align-items-center recurring-schedule">
                    Recurring schedule:  <em style="text-transform: capitalize">{{prod.recurring}}</em>
                </div>
                <div
                    class="d-flex align-items-center media-body flex-items-grow  flex-md-row flex-column"
                    style="flex-basis:20%"
                >
                    {{ prod.desc }}
                </div>
                <div class="action-btns">
                    <button v-if="!prod.stripe_price_id" @click="connectToStripe(prod)" type="button" class="btn btn-info btn-sm connect-stripe-btn">Connect to Stripe</button>
                    <span class="badge badge-success" v-if="!!prod.stripe_price_id">Stripe Connected</span>
                    <button @click="showEditProductModal(prod)" type="button" class="btn link-btn">Edit</button>
                </div>
                
            </div>
            <div
                class="el-card-item align-items-center justify-content-end d-flex"
            >
                    <!-- <button 
                        @click.prevent="$emit('addProduct', [null])"
                        class="btn btn-primary"
                    >Add Product</button> -->
                    <button
                        @click.prevent="showAddProductModal"
                        class="btn btn-primary"
                    >Add Product</button>
            </div>
        </div>
        <transition name="modal-drop-in" mode="out-in">
            <div class="modal fade add-product-modal " tabindex="-1" role="dialog" :id="`add-product-modal-${inst ? inst.id : 'academy'}`" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title" id="grid-title">Add New Product</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div class="modal-body" style="">
                            <div
                                class="form-group bmd-form-group is-filled mt-3 mb-4"
                                :class="!formValidation.display_name ? 'is-invalid' : ''"
                            >
                                <label class="bmd-label-floating">Product Name</label>
                                <input
                                    class="form-control"
                                    @blur="editedFields.push('display_name'); checkFormValidation(newProd);"
                                    v-model="newProd.display_name"
                                />
                            </div>
                            <div
                                class="form-group bmd-form-group mb-4"
                                :class="!formValidation.price ? 'is-invalid' : ''"
                            >
                                <label class="bmd-label-floating">Price</label>
                                <input
                                    class="form-control"
                                    @input="validatePrice($event)"
                                    @blur="editedFields.push('price'); checkFormValidation(newProd);"
                                    v-bind:value="newProd.price"
                                />
                            </div>
                            <div
                                class="form-group bmd-form-group mb-4"
                                :class="!formValidation.credits ? 'is-invalid' : ''"
                            >
                                <label class="bmd-label-floating">Number of Lessons Included</label>
                                <input
                                    class="form-control"
                                    @input="validateCredits($event)"
                                    @blur="editedFields.push('credits'); checkFormValidation(newProd);"
                                    v-bind:value="newProd.credits"
                                />
                            </div>
                            <div
                                class="form-group  bmd-form-group mb-4 is-focused"
                                :class="!formValidation.recurring ? 'is-invalid' : ''"
                            >
                                <label class="bmd-label-floating">Recurring Schedule</label>
                                <div class="bmd-form-group">
                                    <div class="radio">
                                        <label class="radio">
                                            <input
                                                type="radio"
                                                value="once"
                                                @change="checkFormValidation(newProd)"
                                                :name="`recurring-${inst ? inst.id : 'academy'}`"
                                                v-model="newProd.recurring"
                                            />
                                            <span>Once - one time purchase</span>
                                        </label>
                                    </div>
                                </div>
                                <div class="bmd-form-group">
                                    <div class="radio">
                                        <label class="radio">
                                            <input
                                                type="radio"
                                                value="monthly"
                                                @change="checkFormValidation(newProd)"
                                                :name="`recurring-${inst ? inst.id : 'academy'}`"
                                                v-model="newProd.recurring"
                                            />
                                            <span>Monthly - subscription that renews every month from the date of purchase</span>
                                        </label>
                                    </div>
                                </div>
                                <div class="bmd-form-group">
                                    <div class="radio">
                                        <label class="radio">
                                            <input
                                                type="radio"
                                                value="yearly"
                                                @change="checkFormValidation(newProd)"
                                                :name="`recurring-${inst ? inst.id : 'academy'}`"
                                                v-model="newProd.recurring"
                                            />
                                            <span>Yearly - subscription that renews every year from the date of purchase</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="form-group bmd-form-group mb-4"
                                :class="!formValidation.desc ? 'is-invalid' : ''"
                            >
                                <label class="control-label">Edit Description</label>
                                <markdown-editor
                                    :ref="'mde-new' + (inst ? inst.id : '-academy')"
                                    :mode="'wysiwyg'"
                                    :height="'240px'"
                                    @blur="editedFields.push('desc'); checkFormValidation(newProd)"
                                    v-model="newProd.desc"
                                />
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button 
                                type="button"
                                class="btn btn-primary"
                                :disabled="!isFormValid || !canSubmit"
                                @click.prevent="saveEditedProduct(false)">
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
        <transition name="edit-modal-drop-in" mode="out-in">
            <div class="modal edit-product-modal fade" tabindex="-1" role="dialog" :id="`edit-product-modal-${inst ? inst.id : 'academy'}`" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title" id="grid-title">Edit Product</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div class="modal-body" style="">
                            <div
                                class="form-group bmd-form-group mt-3 mb-4"
                                :class="!formValidation.display_name ? 'is-invalid' : ''"
                            >
                                <label class="bmd-label-floating">Product Name</label>
                                <input
                                    class="form-control"
                                    autofocus
                                    @blur="checkFormValidation(editProd, true)"
                                    v-model="editProd.display_name"
                                />
                            </div>
                            <div
                                class="form-group bmd-form-group mb-4"
                                :class="!formValidation.credits ? 'is-invalid' : ''"
                            >
                                <label class="bmd-label-floating">Number of Lessons Included</label>
                                <input
                                    class="form-control"
                                    @input="validateCredits($event, true)"
                                    @blur="checkFormValidation(editProd, true)"
                                    v-bind:value="editProd.credits"
                                />
                            </div>
                            <div
                                class="form-group bmd-form-group mb-4"
                                :class="!formValidation.desc ? 'is-invalid' : ''"
                            >
                                <label class="control-label">Edit Description</label>
                                <markdown-editor
                                    :ref="'mde-edit'"
                                    :mode="'wysiwyg'"
                                    :height="'240px'"
                                    @blur="checkFormValidation(editProd, true)"
                                    v-model="editProd.desc"
                                />
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button
                                type="button"
                                class="btn btn-primary"
                                :disabled="!isFormValid || !canSubmit"
                                @click.prevent="saveEditedProduct(true)">
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
// const VueModel = require('vue-model');
// import Vue from 'vue';
// console.log(Vue);
const ScaleLoader = require('vue-spinner/src/ScaleLoader.vue').default
//import PictureInput from 'vue-picture-input';
import VueModelErrors from '../../lib/VueModelErrors.js'
import MarkdownEditor from '../../components/markdown-editor'

const DEFUALT_DESCRIPTION = `
* Review of your submitted video. Face-on analysis (side-view) of your motion is the most optimal angle for V1's analyzer tool.
* Voice-over analysis.
* Chat communication with teaching professional.
* Identification of your most significant swing faults
* Corrective exercises and drills specific to your development
`

const InitialFormValidation = {
    display_name: true,
    credits: true,
    desc: true,
    recurring: true,
    price: true,
};

export default {
    props: {
        profilePic: {
            type: String,
            default: null,
        },
        inst: {
            type: Object,
            default: null,
        },
        title: {
            type: String,
            default: ""
        },
        productList: {
            type: Array,
            default: [],
        }
    },
    data: () => {
        return {
            newProd: {
                recurring: 'once'
            },
            instructor: {},
            editProd: {},
            isFormValid: false,
            canSubmit: true,
            formValidation: {},
            editedFields: [],
        }
    },
    mixins: [VueModelErrors],
    components:{
        'scale-loader': ScaleLoader,
        'markdown-editor': MarkdownEditor,
    },
    methods: {
        showAddProductModal: function() {
            this.newProd = { desc: DEFUALT_DESCRIPTION };
            this.isFormValid = false;
            this.canSubmit = true;
            this.editedFields = [];
            this.formValidation = { ...InitialFormValidation };
            $(`#add-product-modal-${this.inst ? this.inst.id : 'academy'}`).modal('show');
        },
        showEditProductModal: function(prod) {
            this.editProd = {
                credits: prod.credits,
                desc: prod.desc,
                display_name: prod.display_name,
                prod_code: prod.prod_code
            };
            this.editedFields = [
                'display_name',
                'desc',
                'credits'
            ];
            this.formValidation = { ...InitialFormValidation };
            this.isFormValid = true;
            this.canSubmit = true;
            var self = this;
            $(`#edit-product-modal-${this.inst ? this.inst.id : 'academy'}`).modal('show');
            $(`#edit-product-modal-${this.inst ? this.inst.id : 'academy'}`).addClass('is-filled');
            // editors starting out hidden need to setSize when visible
            $(`#edit-product-modal-${this.inst ? this.inst.id : 'academy'}`).on('shown.bs.modal', function() {
                self.$refs['mde-edit'].editor.getCodeMirror().setSize();
            });
        },
        saveEditedProduct: function(isEditing) {
            if (isEditing) {
                this.checkFormValidation(this.editProd, true);
                if (this.isFormValid) {
                    this.canSubmit = false;
                    this.editProduct();
                }
            }
            else {
                this.checkFormValidation(this.newProd);
                if (this.isFormValid) {
                    this.canSubmit = false;
                    this.createProduct();
                }
            }
        },
        closeDialog: function() {
            $(`#edit-product-modal-${this.inst ? this.inst.id : 'academy'}`).modal('hide');
            $(`#add-product-modal-${this.inst ? this.inst.id : 'academy'}`).modal('hide');
            this.editProd = {};
            this.newProd = {};
        },
        enableSubmit: function() {
            this.canSubmit = true;
        },
        createProduct: function() {
            this.$emit('addProduct', { ...this.newProd, inst_id: this.inst ? this.inst.id : null, academy_code: this.inst ? this.inst.academyCode : null }, this);
        },
        editProduct: function() {
            this.$emit('editProduct', this.editProd, this);
        },
        changeRecurringValue: function(newValue) {
            this.newProd = { ...this.newProd, recurring: newValue };
        },
        validateProduct: function(product, isEditing) {
            return product.display_name && (isEditing || product.price) && product.credits && (isEditing || product.recurring) && product.desc;
        },
        validatePrice: function(event) {
            let regex = /^\d*(\.\d{0,2})?$/;
            let newPrice = event.target.value;

            if (newPrice === '') {
                this.newProd = { ...this.newProd, price: '' };
            } else if (regex.test(newPrice)) {
                let parsableNewPrice = newPrice;
                if (newPrice.endsWith('.')) {
                    parsableNewPrice = newPrice.slice(0, -1);
                }
                let floatValue = parseFloat(parsableNewPrice);
                if (!isNaN(floatValue) && floatValue <= 9999.99) {
                    this.newProd = { ...this.newProd, price: newPrice };
                }
            }
            this.newProd = { ...this.newProd };
        },
        validateCredits: function(event, isEdit) {
            let regex = /^\d*/;
            let newCredits = event.target.value;
            let prod = isEdit ? this.editProd : this.newProd;

            if (newCredits != '' && regex.test(newCredits)) {
                let intValue = parseInt(newCredits);
                if (!isNaN(intValue) && intValue <= 300) {
                    prod = { ...prod, credits: intValue.toString() };
                }
            } else if (newCredits === '') {
                prod = { ...prod, credits: '' };
            }

            if (isEdit) {
                this.editProd = { ...prod };
            } else {
                this.newProd = { ...prod };
            }
        },
        connectToStripe: function(prod) {
            this.$emit('connectToStripe', prod);
        },
        checkFormValidation: function(formData, isEdit = false) {
            this.formValidation = { ...InitialFormValidation };
            this.isFormValid = true;
            if (!formData.display_name) {
                if (this.editedFields.indexOf('display_name') > -1) {
                    this.formValidation.display_name = false;
                }
                this.isFormValid = false;
            }
            if (!formData.desc) {
                if (this.editedFields.indexOf('desc') > -1) {
                    this.formValidation.desc = false;
                }
                this.isFormValid = false;
            }
            if (!formData.credits) {
                if (this.editedFields.indexOf('credits') > -1) {
                    this.formValidation.credits = false;
                }
                this.isFormValid = false;
            }
            if (!isEdit && !formData.price) {
                if (this.editedFields.indexOf('price') > -1) {
                    this.formValidation.price = false;
                }
                this.isFormValid = false;
            }
            if (!isEdit && !formData.recurring) {
                if (this.editedFields.indexOf('recurring') > -1) {
                    this.formValidation.recurring = false;
                }
                this.isFormValid = false;
            }
        }
    }
};
</script>
<style scoped>
    .edit-product-modal > .modal-dialog,
    .add-product-modal > .modal-dialog {
        max-width: 480px;
    }
    .radio-group {
        margin-top: 3em;
        padding-top: 0.5em;       
    }
    .radio {
        display: inline-flex;
        align-items: center;
        margin-right: 1em;
    }
    .radio span {
        padding-left: 0.3em;
    }
    .form-control[disabled] {
        border-bottom: none;
    }
    .recurring-schedule {
        width: 200px;
    }
    .input-group-text {
        padding: 0 5px;
    }
    .connect-stripe-btn {
        font-size: 10px;
        padding: 0.2rem 0.4rem;
        height: 23px;
    }
    .action-btns {
        width: 200px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .action-btns .badge {
        font-size: 10px;
        padding: 4px;
        display: block;
    }
    .link-btn {
        display: inline;
        height: auto;
        width: auto;
        flex: none;
        padding: 3px 12px;
        margin-left: 6px;
        text-transform: capitalize;
    }
    .bmd-form-group.is-invalid  label {
        color: #d50000 !important;
    }
    .bmd-form-group.is-invalid .form-control {
        background-image: linear-gradient(
            0deg
            ,#d50000 2px,rgba(213,0,0,0) 0),linear-gradient(
            0deg
            ,rgba(0,0,0,.26) 1px,transparent 0);
        background-size: 100% 100%, 100% 100%;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        box-shadow: none;
    }
</style>
