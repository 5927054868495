export default {
    attributes: [
        'academyCode',
        'name',
        'address',
        'city',
        'state',
        'postal',
    ],

    http: {
        baseRoute: '/academy/',
        actions: {
            // Don't expose the destroy action
            destroy: false,
            fetch: {
                route: '{academyCode}',
            },
            update: {
                method: 'PATCH',
                route: '{academyCode}',
            },
        },
        getDataFromResponse(response) {
            return response.data.data;
        }
    },
}
