<template>
    <data-table
        :title="'Purchases'"
        :status="'ready'"
        :headers="headers"
        :notLoaded="!loaded"
        :items="itemList"
    >
    <template v-slot:cardbody>
        <h4 class="card-title"> Purchases
            <span class="badge" :class="getBadgeColor('ready')">{{'New' | capitalize }}</span></h4>
    </template>


    <template v-slot:item.user_id="{ item }">
        <img height="28" width="28" :src="getStudentPic(item.user_id)"> {{getStudentName(item.user_id)}}
    </template>
    <template v-slot:item.viewlesson="{ item }">
    <router-link tag="button"
        class="btn btn-default" type="button"
        :to="'/lessons/'+item.id">View Lesson</router-link>
    </template>

    <template v-slot:item.date="{ item }">
        {{item.getStatusChangeTime() | moment-fromnow}}
    </template>
    </data-table>
</template>

<script>
import DataTable from '../mddatatable/DataTableCard.vue';
import LoadsJsonApiData from '../../mixins/loadsJsonApiData.vue';

export default {
    mixins: [LoadsJsonApiData],
    components: {
        DataTable,
    },
    data: function() {
        return {
            'noitems': [],
            'loaded': false,
            'itemModel': 'lesson',
            'url': '/lessons/ready',
            'headers': [
                {text: 'Student', value: 'user_id'},
                {text: '', value: 'viewlesson'},
                {text: 'Date', value: 'date'},
            ],
        }
    },
    methods: {
        getBadgeColor: (status) => {
            switch (status) {
                case 'waiting': return 'badge-warning';
                case 'ready': return 'badge-default';
                case 'assigned': return 'badge-info';
                default: return 'badge-success';
            }
        },
        getStudentPic: function(userId) {
            let student = this.includedList.filter(function(item) {
                if (item.id == userId) return item;
            });
            if (student.length < 1) {
                return '';
            }
            student = student[0] || {};
            return student.pic_url;
        },
        getStudentName: function(userId) {
            let student = this.includedList.filter(function(item) {
                if (item.id == userId) return item;
            });
            if (student.length < 1) {
                return 'N/A';
            }
            student = student[0] || {};
            return student.last_name + ', ' + student.first_name;
        },
    },
    mounted: function() {
        this.getData('market');
    },
}
</script>
