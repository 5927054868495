<template>
    <div class="card">
                        <div class="overlay-spinner" v-show="notLoaded"><scale-loader :loading="notLoaded" :color="color"></scale-loader></div>
                        <div class="card-body">
                                <h4 class="card-title">{{title}} <span class="badge" :class="getBadgeColor(status)">{{status | capitalize }}</span></h4>
                                <div class="mt-3" v-show="notLoaded">
                                <h5>
                                    All caught up!
                                </h5>
                                </div>

                                <div class="table-responsive mt-3" v-show="notLoaded">
                                You don't have any lessons requests.
                                </div>
                                <div class="table-responsive mt-3">
                                    <table class="table table-striped" v-show="!notLoaded">
                                        <thead>
                                            <tr>
                                                <th>Student</th>
                                                <th class="text-nowrap">Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="lesson in dataPreview">
                                                <td><img height="28" width="28" :src="getStudentPic(lesson.user_id)"> {{getStudentName(lesson.user_id)}}</td>
                                                <td class="text-nowrap" :data-timestamp="lesson.getStatusChangeTime() | timestamp">
                                                {{lesson.getStatusChangeTime() | moment-fromnow}}
                                                </td>
                                            </tr>
                                            <tr v-show="dataRemainingCount">
                                                <td colspan="4"> Plus {{dataRemainingCount}} more ...</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                        </div>
    </div>
</template>

<script>
import axios from 'axios';
import auth from '../../lib/auth';
var ScaleLoader = require('vue-spinner/src/ScaleLoader.vue').default;

Vue.filter("wholeNumber", function (value) {
    return Number.parseInt(value);
});
export default {
    components: {
        'scale-loader': ScaleLoader
    },
    props: [
        'title',
        'status',
        'url',
    ],
    data: function() {
        return {
            data: [],
            included: [],
            notLoaded: true,
            color: '#CC2343',
        }
    },
    computed: {
        dataPreview: function() {
            return this.data.slice(0, 5);
        },
        dataRemainingCount: function() {
            if (this.data.length <= 5 ) {
              return false;
            }
            return this.data.length - 5;
        },
        userId: function() {
            return auth.getId();
        },
    },
    methods: {
        getUserId: function() {
            return auth.getId();
        },
        getStudentPic: function(userId) {
            let student = this.included.filter(function(item) {
                if (item.id == userId) return item;
            });
            if (student.length < 1) {
                return '';
            }
            student = student[0] || {};
            return student.attributes['pic_url'];
        },
        getStudentName: function(userId) {
            let student = this.included.filter(function(item) {
                if (item.id == userId) return item;
            });
            if (student.length < 1) {
                return 'N/A';
            }
            student = student[0] || {};
            return student.attributes['last_name'] + ', ' + student.attributes['first_name'];
        },
        getBadgeColor: (status) => {
            switch (status) {
                case 'waiting': return 'badge-warning';
                case 'ready': return 'badge-default';
                case 'assigned': return 'badge-info';
                default: return 'badge-success';
            }
        },
        getData: function() {
            var self = this;
            axios.get(this.url)
                .then( (response) => {
                    this.notLoaded = false;
                    this.data = response.data.data || [];
                    this.data = this.$models('lesson', this.data);

                    this.included = response.data.included || [];
                })
                .error (function (err) {
                    this.$swal({
                        text: "Cannot load lessons.", 
                        type:'error',
                    });
                    this.notLoaded = false;
                });
        },
    },
    mounted: function() {
        this.getData();
    },
}
</script>
