<template>
    <div class="card">
        <div class="card-body">
            <img
                :src="'https://www.v1sports.com/Academy/' + item['academy_code'] + '/images/' + item['academy_code'] + '_logo.jpg'"
                onError="this.onerror=null;this.src='/dist/images/v1-sports-logo.png';"
                class="rounded-circle"
                width="100"
            >
            {{ item['name'] }}
            {{item['city']}}, {{item['state']}}
            <a
                @click="$emit('reloadWithBrand', item)"
                :data-academy-code="item['profile_slug']"
                class="btn btn-brand btn-round btn-rounded text-white pull-right"
            >View this academy</a>

        </div>
    </div>
</template>

<script>
Vue.filter("wholeNumber", function (value) {
    return Number.parseInt(value);
});
export default {
    props: ['item'],
    data: function() {
        return {
            academyList: [],
        }
    },
}
 </script>
