<template>
    <div class="card">
        <slot name="loading-overlay" v-if="showLoadingOverlay">
           <div class="overlay-spinner" v-show="notLoaded"><scale-loader :loading="notLoaded" :color="color"></scale-loader></div>
        </slot>
            <slot name="cardheader">
            </slot>

                        <div class="card-body">
                            <slot name="cardbody">
                            </slot>

                                <div class="mt-3" v-show="notLoaded">
                                </div>

                                <div class="table-responsive mt-3" v-show="notLoaded">
                                    <slot name="norecords">
                                        No records.
                                    </slot>
                                </div>
                                <div class="table-responsive mt-3">
                                    <table class="table table-striped" v-show="!notLoaded">
                                        <thead>
                                            <tr>
                                                <th v-for="hdr in headers" :width="hdr.width">
                                                    <slot :name="['header.'+hdr.value]" v-bind:item="hdr">
                                                        <span v-html="hdr.text"></span>
                                                    </slot>
                                                    <slot
                                                        :name="['filter.'+hdr.value]"
                                                        v-bind:item="hdr"
                                                        v-if="filterCols.indexOf(hdr.value)!== -1"
                                                    >
                                                        <br/>
                                                        <input
                                                            type="text" :name="['filter.'+hdr.value]"
                                                            @input.prevent="debounce(() => {filter(hdr, $event)})"
                                                        >
                                                    </slot>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in dataPreview" v-bind:key="item.id">
                                                <td v-for="hdr in headers">
                                                    <slot :name="['item.'+hdr.value]" v-bind:item="item" v-bind:index="index + (pageIdx*pageSize)">
                                                    {{getItemProp(item, hdr.value)}}
                                                    </slot>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <slot name="footer">
                                                <td :colspan="headers.length -1" v-show="pageIdx==0 && dataRemainingCount > 0"> Plus {{dataRemainingCount}} more ...</td>
                                                <td :colspan="headers.length -1" v-show="pageIdx > 0"> viewing {{(pageIdx*pageSize)+1}} - {{pageEndCount}}</td>
                                                <td>
                                                    <div
                                                        v-if="pageIdx > 0 || dataRemainingCount"
                                                    >
                                                        <a href="" :disabled="dataRemainingCount>1" @click.prevent="prev"><i class="material-icons">navigate_before</i></a>
                                                        <a href="" @click.prevent="next"><i class="material-icons">navigate_next</i></a>
                                                    </div>
                                                </td>
                                                </slot>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                        </div>
    </div>
</template>

<script>
import auth from '../../lib/auth';
var ScaleLoader = require('vue-spinner/src/ScaleLoader.vue').default;

Vue.filter("wholeNumber", function (value) {
    return Number.parseInt(value);
});
export default {
    components: {
        'scale-loader': ScaleLoader
    },
    props: {
        'notLoaded': Boolean,
        'status': String,
        'headers': Array,
        'items': {
            type: Array,
        },
        'pageSize': {
            type: Number,
            default: 10,
        },
        'showLoadingOverlay': {
            type: Boolean,
            default: true,
        },
        'filterCols': {
            type: Array,
            default: () => [],
        },
    },
    data: function() {
        return {
            data: [],
            included: [],
            color: '#CC2343',
            pageIdx: 0,
            timeout: 0,
            filterParams: [],
        }
    },
    computed: {
        filteredItemList: function() {
            if (this.filterParams.length == 0) {
                return this.items;
            }
            return this.items.filter((item) => {
                return this.filterParams.some((filter) => {
                    if (filter.hdr.derived) {
                        return eval(filter.hdr.derived).toString().match( new RegExp(filter.val, 'i'));
                    }
                    return item[filter.hdr.value].match( new RegExp(filter.val, 'i'));
                });
            });
        },
        pageEndCount: function() {
            if (((this.pageIdx*this.pageSize)+this.pageSize) > this.filteredItemList.length) {
                return this.filteredItemList.length;
            }
            return this.pageSize*(this.pageIdx+1);
        },
        dataPreview: function() {
            return this.filteredItemList.slice((this.pageIdx*this.pageSize), (this.pageIdx*this.pageSize)+this.pageSize);
           // return this.filteredItemList.slice((this.pageIdx*this.pageSize), (this.pageIdx*this.pageSize)+this.pageSize);
        },
        dataRemainingCount: function() {
            if (this.filteredItemList.length <= (this.pageIdx*this.pageSize)+this.pageSize ) {
                return false;
            }
            return this.filteredItemList.length - ((this.pageIdx*this.pageSize)+this.pageSize);
        },
        userId: function() {
            return auth.getId();
        },
    },
    methods: {
        debounce: function(cb) {
            if (this.timeout) {
                window.clearTimeout(this.timeout);
                this.timeout = 0;
            }
            this.timeout = window.setTimeout(cb, 800);
        },
        filter: function(hdr, event) {
            this.filterParams = [];
            this.filterParams.push({hdr:hdr, val: event.target.value});
        },
        prev: function() {
            if (this.pageIdx > 0) {
                this.pageIdx--;
            }
        },
        next: function() {
            if (((this.pageIdx*this.pageSize)+this.pageSize) >= this.filteredItemList.length) {
                return;
            }
            this.pageIdx++;
        },
        getItemProp: function(item, prop) {
            //const value = getObjectValueByPath(item, header.value)
            const value = item[prop];
            return value;
        },
        getUserId: function() {
            return auth.getId();
        },
        getStudentPic: function(userId) {
            let student = this.included.filter(function(item) {
                if (item.id == userId) return item;
            });
            if (student.length < 1) {
                return '';
            }
            student = student[0] || {};
            return student.attributes['pic_url'];
        },
        getStudentName: function(userId) {
            let student = this.included.filter(function(item) {
                if (item.id == userId) return item;
            });
            if (student.length < 1) {
                return 'N/A';
            }
            student = student[0] || {};
            return student.attributes['last_name'] + ', ' + student.attributes['first_name'];
        },
        getBadgeColor: (status) => {
            switch (status) {
                case 'waiting': return 'badge-warning';
                case 'ready': return 'badge-default';
                case 'assigned': return 'badge-info';
                default: return 'badge-success';
            }
        },
    },
}
</script>
