const Academy = require('../pages/Academy.vue').default;
Vue.component('academy', Academy);

const Dashboard = require('../pages/Dashboard.vue').default;
Vue.component('dashboard', Dashboard);

const ProductPage = require('../pages/ProductPage.vue').default;
Vue.component('products', ProductPage);

const AcademiesPage = require('../pages/AcademiesPage.vue').default;
Vue.component('academies', AcademiesPage);

const LessonsPage = require('../pages/LessonsPage.vue').default;
Vue.component('lessons', LessonsPage);

const LessonDetailPage = require('../pages/LessonDetailPage.vue').default;
Vue.component('lesson-detail', LessonDetailPage);

const VideosPage = require('../pages/VideosPage.vue').default;
Vue.component('videos', VideosPage);

const StudentsPage = require('../pages/StudentsPage.vue').default;
Vue.component('students', StudentsPage);

const StudentProfile = require('../pages/StudentProfile.vue').default;
Vue.component('student-profile', StudentProfile);

const ProfilePage = require('../pages/ProfilePage.vue').default;
Vue.component('profile-page', ProfilePage);

import auth from './auth';

export default [
    { path: '/home', component: { template: '<div class="home"><keep-alive><router-view></router-view></keep-alive></div>' },
        children: [
            { path: '', name: 'Home', component: Dashboard, meta: { breadcrumb: 'Home', to: '/home'},},
        ]
    },
    { path: '/products', name: 'Products',
        component: ProductPage,
        meta: { breadcrumb: 'Products', to: '/products'},
        props: { showSelectAcademy: true, },
    },
    { path: '/academy', name: 'Academy',
        component: Academy,
        props: { academyCode: auth.user.academyCode },
        meta: { breadcrumb: 'Digital Lessons', to: '/products'},
    },
    { path: '/academies', name: 'Academies',
        component: AcademiesPage,
        meta: { breadcrumb: 'Academies', to: '/academies'},
    },
    { path: '/lessons/', name: 'Lessons',
        component: { template: '<div class="home"><keep-alive><router-view></router-view></keep-alive></div>' },
        meta: { breadcrumb: 'Lessons', to: '/lessons'},
        children: [
            { path: '',    component: LessonsPage, props:true },
            { path: ':id', component: LessonDetailPage, props:true },
        ],
    },
    { path: '/videos', name: 'Video Locker',
        component: VideosPage,
        meta: { breadcrumb: 'Video Locker', to: '/videos'},
    },
    { path: '/profile', name: 'Profile',
        component: ProfilePage,
        meta: { breadcrumb: 'Profile', to: '/profile'},
    },
    { path: '/students/', name: 'students',
        //component: StudentsPage,
        component: { template: '<div class="home"><keep-alive><router-view></router-view></keep-alive></div>' },
        meta: { breadcrumb: 'Students', to: '/students'},
        children: [
            { path: '', component: StudentsPage},
            { path: ':account_id', name: 'studentdetail', component: StudentProfile, props:true },
        ],
    },
];
