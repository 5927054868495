<template>
<div>
      <div class="row">
        <div class="col-12 col-md-6 col-lg-3">

          <card-stat v-bind:stat="'Lessons Completed'" v-bind:val="homepageStats.completedCount" v-bind:icon="'assignment_turned_in'" v-bind:icon-type="'success'">over the last 14 days</card-stat>

        </div>
        <div class="col-12 col-md-6 col-lg-3">

          <card-stat v-bind:stat="'Lessons Due Soon'" v-bind:val="homepageStats.waitingCount" v-bind:icon="'assignment_late'" v-bind:icon-type="'primary'">
                <i class="material-icons text-danger">calendar_today</i>
                <a href="#">View Lessons</a>
          </card-stat>

        </div>
        <div class="col-12 col-md-6 col-lg-3">

          <card-stat v-bind:stat="'Forthcoming Lessons'" v-bind:val="homepageStats.readyCount" v-bind:icon="'assignment'" v-bind:icon-type="'warning'">
          </card-stat>

        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <card-stat v-bind:stat="'Students'" v-bind:val="studentCount" v-bind:icon="'account_circle'"></card-stat>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-6">
<todo-lesson-table
    :title="'Todo Lessons'"
    :url="'/lessons/scheduled'"
    :status="'assigned'"
    ref="todoLessons"
    v-on:lessonsChanged="refreshData"></todo-lesson-table>
        </div>

        <div class="col-12 col-md-6">
<completed-lesson-table
    :title="'Completed'"
    ref="completedLessons"
    :url="'/lessons/completed'" :status="'completed'"></completed-lesson-table>
        </div>
      </div>


      <div class="row">
        <div class="col-12">
            <new-lesson-table
            ></new-lesson-table>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
        <keep-alive>
            <news-feed></news-feed>
        </keep-alive>
        </div>
      </div>
</div>
</template>

<script>
import axios from 'axios';
import CompletedLessonTable from '../components/instructor/CompletedLessonTable.vue';
import TodoLessonTable from '../components/instructor/TodoLessonTable.vue';
import NewLessonTable from '../components/instructor/NewLessonTable.vue';

export default {
    components: {
        'completed-lesson-table': CompletedLessonTable,
        'todo-lesson-table': TodoLessonTable,
        'new-lesson-table': NewLessonTable,
    },

    data: function() {
        return {
            homepageStats: {
                studentCount: null,
                waitingCount: null,
                readyCount: null,
                completedCount: null,
            },
        }
    },
    computed: {
        studentCount: function() {
            if (this.homepageStats.studentCount === null) {
                return NaN;
            }
            return  this.homepageStats.studentCount;
        }
    },
    mounted: function() {
        var self = this;
        axios.get('/home/dashboard-stats')
            .then( (response) => {
                self.homepageStats.studentCount   = response.data.studentCount;
                self.homepageStats.waitingCount   = response.data.waitingLessonCount || 0;
                self.homepageStats.waitingCount  += response.data.assignedLessonCount || 0;

                self.homepageStats.readyCount     = response.data.readyLessonCount || '?';
                self.homepageStats.completedCount = response.data.completedLessonCount || '?';
        });
    },

    methods: {
        getData: function() {
            var self = this;
            axios.get('/home/dashboard-stats')
                .then( (response) => {
                    self.homepageStats.studentCount   = response.data.studentCount;
                    self.homepageStats.waitingCount   = response.data.waitingLessonCount || 0;
                    self.homepageStats.waitingCount  += response.data.assignedLessonCount || 0;

                    self.homepageStats.readyCount     = response.data.readyLessonCount || '?';
                    self.homepageStats.completedCount = response.data.completedLessonCount || '?';
            });
        },
        refreshData: function() {
            this.getData();
            this.$refs.todoLessons.getData();
            this.$refs.completedLessons.reloadData();
        },
    }
}

</script>
