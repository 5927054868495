<template>
            <div class="card card-stats">

                <div :class="iconClass">
                    <div class="card-icon">
                        <i class="material-icons" v-html="icon"></i>
                    </div>
                    <p class="card-category" v-html="stat"></p>
                    <h3 class="card-title"><span v-html="val"></span> <small v-html="unit"></small></h3>
                </div>

                <div class="card-footer">
                    <div class="stats">
                    <slot></slot>
                    </div>
                </div>
            </div>

</template>

<script>
export default {
    name: 'card-stat',
    props: {
        stat:      { type: String, default: ''},
        val:       { default: ''},
        icon:      { type: String, default: ''},
        unit:      { type: String, default: ''},
        iconType:  { type: String, default: 'info'},
    },
    data: function() {
        return {
            iconClass: "card-header card-header-icon card-header-" + this.iconType,
        };
    },
}
</script>
