
// URL and endpoint constants
const API_URL = '/'
const ACADEMY_URL = API_URL + 'home/selectedacademy/'
const ACADEMY_BRAND_URL = API_URL + 'academy/brand/'
const ACADEMY_JOIN_URL = API_URL + 'academy/{code}/join/'

export default {

  academyCode: '',
  sportId: '',
  brand: {
      name: '',
  },
  academyName() {
      return this.brand['name'];
  },

    /*
  async enrollAcademy(code) {
      var self = this
      return await axios.post(ACADEMY_JOIN_URL.replace("{code}", code)).then( (data) => {
          let academyCode = data.data.data.academy_code;
          self.academyCode = academyCode;
          return academyCode;
      });
      //self.getAcademyXhr();
      //self.getAcademyBrandXhr();
  },
  */

  loadAcademy() {
      if (window.__academy) {
          this.sportId = window.__academy.sportId;
      }
  },

  async getAcademyCode() {
      const bodybody = document.getElementsByTagName('body')
      let body = {};
      Array.from(bodybody).forEach((x)=>{ body = x});
      this.academyCode = body.getAttribute('data-acbc');
      this.loadAcademy();

      if (!this.academyCode || this.academyCode == '') {
          await this.getAcademyXhr();
      }
  },
  getAcademyBrandXhr() {
      let self = this;
      return axios.get(ACADEMY_BRAND_URL+this.academyCode).then( (data) => {
          self.brand = data.data.data;
      },(err) => {
          self.resetBrand();
      });
  },
  resetBrand: function() {
      this.brand = {};
  },
  getAcademyXhr() {
    var self = this;
    return axios.get(ACADEMY_URL).then( (data) => {
      self.academyCode = data.data.academyCode;
    },(err) => {
      //self.academyCode = -1;
    });
  },
}
