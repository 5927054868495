const Model = require('vue-model/src/Model.js').default;

Model.prototype.setValidationErrors = function(error) {

    var http = this.settings.http;
    if (!http.isValidationError(error)) {
        return;
    }
    var errors = http.getErrorsFromResponse(error.response);
    //filter for json-api compatibility
    var flippedErrors = {};
    for (const idx in errors) {
        const err = errors[idx];
        if (err.status == 422) {
            flippedErrors[err.source] = err.detail;
        }
    }

    // errorKey can be a dot delimited path
    Vue.setNested(this.data, http.errorKey, new this.classes.errors(flippedErrors));
}
module.exports = Model; 
