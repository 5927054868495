export default {

    state: {
        products: [],
    },

    handling: 0,
    id: null,

    getProducts() {
        return this.state.products;
    },

    clear() {
        this.state.products = [];
    },

    addProduct(product) {
        this.state.products.push(product);
    },

    subTotal() {
        var tot = 0;
        this.state.products.forEach( item => {
            tot += Number.parseFloat(item.prod.price);
        });

        return tot.toFixed(2);
    },

    itemCount() {
        var ct = 0;
        this.state.products.forEach( item => {
            ct += Number.parseInt(item.qty);
        });

        return ct;
    },
}
