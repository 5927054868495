
// URL and endpoint constants
const API_URL = '/'
const LOGIN_URL = API_URL + 'login/'
const SIGNUP_URL = API_URL + 'users/'
const ACADEMY_URL = API_URL + 'home/selectedacademy/'
const MIX_API_ACCOUNT_BASEURL= process.env.MIX_API_ACCOUNT_BASEURL+'/api201902';

//const axios = require('axios');
require('../../bootstrap');
export const auth = {

  // User object will let us check authentication status
  user: {
    authenticated: false,
    academyCode: '',
    profilePic: '',
    firstName: '',
    lastName: '',
    academyList: [],
    ownedList: [],
    academy: null,
    isInstructor: function() {
      var jwt   = auth.getJwt();
      return jwt['inst'] == 1;
    },
  },
  isNotInstructor() {
      var jwt   = this.getJwt();
      console.log(jwt);
      return jwt && (jwt.inst != 1);
  },
  isInstructor() {
      var jwt   = this.getJwt();
      console.log(jwt);
      return jwt && (jwt.inst == 1);
  },
  getId() {
      var jwt   = this.getJwt();
      return parseInt(jwt['aid'], 10);
  },
  getProfileData(cb) {
    if (Number.isNaN(this.getId())) {
        return Promise.reject();
    }
    return axios.get(MIX_API_ACCOUNT_BASEURL+'/me',
        {headers: this.getAuthHeader()},
    ).then( (data) => {
        cb(data);
    });
  },
  getAcademyCode() {
      var jwt   = this.getJwt();
      return jwt['accid'] || '';
  },
  getJwtToken() {
      var token = this.getCookie('token') || '';
      if (token != '') {
          localStorage.setItem('access_token', token);
      }
      return token;
  },
  getJwt() {
      var token = this.getJwtToken();
      var jwt;
      try {
          jwt = JSON.parse(atob(token.split('.')[1]));
      } catch(error) {
          return {};
      }
      if (jwt['exp'] && jwt['exp'] < Date.now() / 1000) {
          return {};
      }
      return jwt;
  },
  getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  },
  login(context, creds, redirect) {
    return axios.post(LOGIN_URL, creds).then( (data) => {
      localStorage.setItem('id_token', data.id_token)
      localStorage.setItem('access_token', data.access_token)

      this.user.authenticated = true

      // Redirect to a specified route
      /*
      if(redirect) {
        router.go(redirect)        
      }
      */

    }).error((err) => {
      context.error = err
    })
  },

  signup(context, creds, redirect) {
    axis.post(SIGNUP_URL, creds, (data) => {
      localStorage.setItem('id_token', data.id_token)
      localStorage.setItem('access_token', data.access_token)

      this.user.authenticated = true

      if(redirect) {
        router.go(redirect)        
      }

    }).error((err) => {
      context.error = err
    })
  },

  // To log out, we just need to remove the token
  logout() {
    localStorage.removeItem('id_token')
    localStorage.removeItem('access_token')
    this.user.authenticated = false
  },

  checkAuth() {
    var jwt = localStorage.getItem('id_token')
    if(jwt) {
      this.user.authenticated = true
    }
    else {
      this.user.authenticated = false      
    }
  },
  doesOwnAcademy(academyCode) {
    return this.ownedList && this.ownedList.length > 0 &&
          this.ownedList.filter((item) => {
              return item.id == academyCode;
          }).length > 0;
  },
  // The object to be passed as a header for authenticated requests
  getAuthHeader() {
    return {
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    }
  }

};
export default auth;

auth.user.authenticated  = Boolean(auth.getId());
auth.user.academyCode    = auth.getAcademyCode();
auth.user.doesOwnAcademy = auth.doesOwnAcademy;
if (auth.user.authenticated) {
    auth.getProfileData((response)=> {
        auth.user.profilePic    = response.data.data.attributes.profile_pick;
        auth.user.firstName     = response.data.data.attributes.first_name;
        auth.user.lastName      = response.data.data.attributes.last_name;
        auth.user.email         = response.data.data.attributes.email;
        auth.user.academyId     = response.data.data.attributes.academy_id;
        if (response.data.data.relationships && response.data.data.relationships.academies) {
            auth.user.academyList   = response.data.data.relationships.academies.data;
            auth.user.ownedList     = response.data.data.relationships.owned_academies.data;
            auth.user.academy       = response.data.included.find((item) => {
                return item.type == 'academy' && item.id == auth.user.academyId;
            });
            if (auth.user.academyList.length > 1) {
                document.getElementById('menu-user-account-switcher').classList.remove('d-none');
                document.getElementById('menu-user-account-switcher').nextSibling.nextSibling.classList.remove('d-none');
            }
        }
    });
}
