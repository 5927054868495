<template>
    <student-table-inst></student-table-inst>
</template>
<script>

const StudentTable = require('../components/instructor/StudentTable.vue').default;
export default {
    components: {
        'student-table-inst': StudentTable,
    },

};
</script>
