<template>
                <div class="tile">
                    <div class="header-element">
                        <h3>Recent V1 News</h3>
                    </div>
                    <div class="news news_list row">

                      <div class="col-md-6 col-12 mb-4" v-for="item in newsitems">
                          <div class="news_item_block card p-3">
                              <h2><a target="_blank" :href="item.permalink">{{item.title}}</a></h2>
                              <h5>Posted on {{item.date_published | dateFormat}}</h5>
                              <div class="text">
                              <span v-html="item.content_html"></span>
                              </div>
                          </div>
                      </div>
                    </div>

              </div>
</template>
<script>
import axios from 'axios';

export default {
    data: function() {
        return {
            newsitems: []
        }
    },
    mounted: function() {
        var self = this;
        if (this.newsitems.length > 0) return;
        axios.get('/feed/')
            .then( function(response) {
                self.newsitems = response.data.items;
            })
            .catch (function (err) {
                console.error(err);
            });
    }
}
</script>
