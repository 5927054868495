<template>
      <div class="col-12">
                <div class="card">
                    <div class="card-header card-header-icon card-header-vos">
                        <div class="card-icon">
                            <i class="material-icons">play_circle_filled</i>
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="text-right pull-right">
                            <button class="btn btn-secondary" @click="cancel()">Cancel</button>
                            <button class="btn btn-success" @click="$emit('attachmentsSelected')" :disabled="this.selectedCount==0">Attach {{this.selectedCount}} videos</button>
                        </div>
                        <h4>Attach videos</h4>
                    </div>
                    <div class="card-body">

                            <div class="row">
                                <div class="col-md-3 col-sm-12" v-for="(video,index) in itemPreview">
                                    <div class="card">
                                        <div class="overlay"
                                            :data-video-id="video.id">
                                            <img class="card-img-top img-responsive"
                                                v-bind:src="video.thumb_url"
                                                alt="Card image cap"
                                                @error="imageFailed"
                                                @click.prevent="selectVideo"
                                                :data-video-url="video.video_url">
                                            <div class="playWrapper" :class="{selected: video.selected}"
                                                @click.prevent="selectVideo(video, index)"
                                                :data-video-url="video.video_url">

                                                <i class="material-icons" v-show="!video.selected"
                                                    :data-video-url="video.video_url">check_box_outline_blank</i>

                                                <i class="material-icons text-success" v-show="video.selected"
                                                    :data-video-url="video.video_url">check_box</i>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                        <h4 class="card-title"><span :title="video.title">{{video.title | first20Chars}}</span></h4>
                                        <h6 class="card-title"><span :title="video.date_uploaded">{{video.date_uploaded | humanDateDiff}}</span></h6>
                                        </div>
                                    </div>
                                </div>
                          </div>

                    </div>
                    <div class="card-body">

                        <slot name="footer">
                        <div class="pull-right">
                            <a href="" :disabled="dataRemainingCount>1" @click.prevent="prev"><i class="material-icons">navigate_before</i></a>
                            <a href="" @click.prevent="next"><i class="material-icons">navigate_next</i></a>
                        </div>

                        <div v-show="pageIdx === 0"> Plus {{dataRemainingCount}} more ...</div>
                        <div v-show="pageIdx > 0"> viewing {{(pageIdx*itemsPerPage)+1}} - {{pageEndCount}}</div>
                        </slot>
                    </div>

                </div>
      </div>
</template>

<script>
import failedThumbnailVue from "../../mixins/failedThumbnail.vue";

export default {
    mixins: [failedThumbnailVue],
    props: {
        'videoLockerList': {},
        'itemsPerPage': {
            type: Number,
            default: 12,
        },
    },
    data: function() {
        return  {
            selectedCount: 0,
            loading: false,
            pageIdx: 0,
        }
    },
    computed: {
        itemPreview: function() {
            return this.videoLockerList.slice((this.pageIdx*this.itemsPerPage), (this.pageIdx*this.itemsPerPage)+this.itemsPerPage);
        },
        pageEndCount: function() {
            if (((this.pageIdx*this.itemsPerPage)+this.itemsPerPage) > this.videoLockerList.length) {
                return this.videoLockerList.length;
            }
            return this.itemsPerPage*(this.pageIdx+1);
        },
        dataRemainingCount: function() {
            if (this.videoLockerList.length <= (this.pageIdx*this.itemsPerPage)+this.itemsPerPage ) {
                return false;
            }
            return this.videoLockerList.length - ((this.pageIdx*this.itemsPerPage)+this.itemsPerPage);
        },
    },
    methods: {
        cancel: function() {
            this.selectedCount = 0;

            this.videoLockerList.forEach((item) => {
                item.selected = false;
            });
            Vue.set(this.videoLockerList, this.videoLockerList);
            this.$emit('cancel');
        },

        selectVideo: function(video, index) {
            video.selected = !video.selected

            if (video.selected) {
                this.selectedCount++;
            } else {
                if (this.selectedCount > 0) {
                this.selectedCount--;
                }
            }
        },
        imageFailed: function(evt) {
            if (evt.target.getAttribute('data-failed')) {
                return this.imageFailedFinal (evt);
            }
            evt.target.src = '/dist/images/locker_placeholder.png';
            evt.target.setAttribute('data-failed', 'true');
            var stagger = Math.floor(Math.random() * (1800 - 400 + 1)) + 400;

            //FIXME: don't hardcode in a static ip for thumbnailer
            //stagger load because this is not load balanced
            window.setTimeout(
            () => {
                evt.target.src="https://vthumb.do.v1sports.com/?fmt=jpg&url="+evt.target.getAttribute('data-video-url');
            },stagger);
        },
        imageFailedFinal: function(evt) {
            console.log('final image failed');

            if (evt.target.src == '/dist/images/locker_placeholder.png') {
                return;
            }
            evt.target.src = '/dist/images/locker_placeholder.png';
        },
        prev: function() {
            this.removeDataFailed();
            if (this.pageIdx > 0) {
                this.pageIdx--;
            }
        },
        next: function() {
            this.removeDataFailed();
            if (((this.pageIdx*this.itemsPerPage)+this.itemsPerPage) >= this.videoLockerList.length) {
                return;
            }
            this.pageIdx++;
        },
        removeDataFailed: function() {
            var elements = document.querySelectorAll('img[data-failed]');
            Array.prototype.forEach.call(elements, function(el, i){
                el.removeAttribute('data-failed');
            });
        },
    },
    mounted: function() {
        var self = this;
        this.videoLockerList.forEach(function(item) {
            if (item.selected) {
                self.selectedCount++;
            }
        });
    },
}
</script>
<style>
.overlay .playWrapper.selected i,
.overlay .playWrapper.selected {
    opacity: 1;
}
</style>
